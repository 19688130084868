import React from 'react'

import { useQuery } from 'src/hooks';
import { useEffect } from 'react';
import trainingsService from 'src/services/trainings.service';

const TrainingV4 = () => {
  const query = useQuery()

  useEffect(() => {
    trainingsService.registerFishing(query.get('id')).then(() => {
      window.location.assign('https://www.google.com/')
    }, () => {
      window.location.assign('https://www.google.com/')
    })
      .catch(() => {
        window.location.assign('https://www.google.com/')

      })
  }, [])


  return (
    <></>
  )
}

export default TrainingV4
