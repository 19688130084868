import React, { useState } from 'react'
import { useEffect } from 'react';

import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.css'
import { ApiProvider } from '@reduxjs/toolkit/query/react';
import DashBoard from './pages/dashboard'

import Nav from './common/nav/Nav'

import LoginPage from './pages/login'
import TrainingCards from './pages/training-cards'
import RewardPage from './pages/reward'
import Trainings from './pages/trainings'
import TrainingCardsV2 from './pages/training-cards-v2'
import JiraLogin from './pages/jira-login'
import userService, { userApi } from './services/user.service';
import TrainingCardsV3 from './pages/training-cards-v3';
import AuthLinkPage from './pages/auth-link';
import { createContext } from 'react';
import TrainingV4 from './pages/training-v4';
import GoogleRedirectSsoPage from './pages/google-redirect-sso';
import OutlookRedirectSsoPage from './pages/outlook-redirect-sso';
import PhishedPage from './pages/phished';

import { parseJwt, useQuery } from './hooks';
import LoadingIndicator from './common/LoadingIndicator';
import NotFoundPage from './pages/not-found';


import i18next from './i18n'

const AppContent = ({ children, light }) => <div className={`app-content ${light && 'app-content-light'}`}>{children}</div>

const AppLayout = ({ onShowTips }) => {
  const handleShowTips = () => {
    onShowTips(true)
  }

  return (
    <div style={{ backgroundImage: 'url("/images/grid.png")', backgroundSize: '1440px' }} className='app-layout column'>
      <Nav handleShowTips={handleShowTips} />
      <AppContent>
        <Outlet />
      </AppContent>
    </div>
  )
}
const AppLayoutLight = () => {
  return (
    <div style={{ backgroundImage: 'url("/images/grid.png")', backgroundSize: '1440px', backgroundColor: '#fff' }} className='app-layout app-layout-light column'>
      <Nav light={true} />
      <AppContent>
        <Outlet />
      </AppContent>
    </div>
  )
}



function RequireAuth({ children }) {
  const query = useQuery()
  const navigate = useNavigate()
  let location = useLocation();

  const localUserToken = localStorage.getItem('app_user') ? JSON.parse(localStorage.getItem('app_user')).accessToken : null;
  const uriToken = query.get('accessToken') || null

  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  const [loading, setLoading] = useState(uriToken ? true : !localUserToken)
  const [authirized, setAuthirized] = useState(null)

  const tokenExpired = (token) => {
    return parseJwt(token).exp < timestamp / 1000
  }

  const getAccountData = (withUriToken = false) => {
    userService.getAccountData().then((accountData) => {
      setLoading(false)

      if (accountData.data?.language) {
        localStorage.setItem('lang', accountData.data?.language == 'Polish' ? 'pl' : 'en')
        i18next.changeLanguage(accountData.data?.language == 'Polish' ? 'pl' : 'en')
      } else if (accountData.data?.companyLanguage == 'Polish') {
        localStorage.setItem('lang', 'pl')
        i18next.changeLanguage('pl')
      } else {
        localStorage.setItem('lang', 'en')
        i18next.changeLanguage('en')
      }

      if (withUriToken) {
        query.delete('accessToken')
      }
      setAuthirized(true)
      navigate(location.pathname + '?' + query.toString())

    }).catch(err => {
      console.error(err)
      setLoading(false)
      navigate('/login', { state: { error: 'Login data expired. Please try login again' } })
    })
  }

  useEffect(() => {
    if (uriToken) {
      localStorage.removeItem('app_user')

      if (tokenExpired(uriToken)) {
        navigate('/login', { state: { error: 'Login data expired. Please try login again' } })
      } else {
        localStorage.setItem("app_user", JSON.stringify({ accessToken: uriToken }))
        getAccountData(true)
      }
    } else {
      if (localUserToken) {
        if (tokenExpired(localUserToken)) {
          localStorage.removeItem('app_user')
          navigate('/login', { state: { error: 'Login data expired. Please try login again' } })
        } else {
          getAccountData()
        }
      } else {
        setLoading(false)
        setAuthirized(false)
      }
    }
  }, [])


  return (<>
    {loading ? <div style={{ height: '80vh' }}><LoadingIndicator /></div> : <>
      {authirized && children}
      {!authirized && authirized !== null && <Navigate to="/login" state={{ from: location }} replace />}
    </>}
  </>);
}
export const SettingsContext = createContext();

function App() {
  const [tips, setTips] = useState(false);
  const [lang, setLang] = useState('English')

  return (
    <div>
      <ApiProvider api={userApi}>
        <SettingsContext.Provider value={{ showTips: tips, lang: lang, setLang: setLang }}>
          <Routes>
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/' element={<RequireAuth><AppLayout onShowTips={setTips} /></RequireAuth>}>
              <Route index element={<DashBoard onCloseTips={setTips} />} />
            </Route>
            <Route path='/trainings' element={<RequireAuth><AppLayoutLight /></RequireAuth>}>
              <Route index element={<Trainings />} />
            </Route>

            <Route path='/login' element={<LoginPage />} />
            <Route path='/auth-link' element={<AuthLinkPage />} />

            <Route path='/google-sso-redirect' element={<GoogleRedirectSsoPage />} />
            <Route path='/outlook-sso-redirect' element={<OutlookRedirectSsoPage />} />

            <Route path='/cards' element={<TrainingCards />} />
            <Route path='/cards-v2' element={<TrainingCardsV2 />} />
            <Route path='/phished' element={<PhishedPage />} />
            <Route path='/cards-v3' element={<RequireAuth><TrainingCardsV3 /></RequireAuth>} />
            <Route path='/training-v4' element={<TrainingV4 />} />
            <Route path='/no-reply/verification' element={<JiraLogin />} />
            <Route path='/reward' element={<RewardPage />} />
            <Route path='/user' element={<LoginPage />} />
          </Routes>
        </SettingsContext.Provider>
      </ApiProvider>

    </div>
  )
}

export default App
