export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('app_user'));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uriToken = urlParams.get('accessToken')

  if (uriToken) {
    return { Authorization: 'Bearer ' + uriToken };
  }
  else if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken };
  } else {
    return {};
  }
}