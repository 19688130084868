import React from 'react'

const TrainingIcon = ({ light = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path opacity="0.2" d="M3.375 10.3984V15.5098C3.37469 15.6716 3.42699 15.8291 3.52402 15.9585C4.15558 16.7989 6.82004 19.8734 12 19.8734C17.18 19.8734 19.8444 16.7989 20.476 15.9585C20.573 15.8291 20.6253 15.6716 20.625 15.5098V10.3984L12 14.9984L3.375 10.3984Z" fill={light ? '#1B1B26' : 'white'} />
      <path d="M0.75 9L12 3L23.25 9L12 15L0.75 9Z" stroke={light ? '#1B1B26' : 'white'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.625 22.5V12L12 9" stroke={light ? '#1B1B26' : 'white'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.625 10.3984V15.5098C20.6253 15.6716 20.573 15.8291 20.476 15.9585C19.8444 16.7989 17.18 19.8734 12 19.8734C6.82004 19.8734 4.15558 16.7989 3.52402 15.9585C3.42699 15.8291 3.37469 15.6716 3.375 15.5098V10.3984" stroke={light ? '#1B1B26' : 'white'} strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>

)

export default TrainingIcon
