export const jiraPage = `

        *, :after, :before {
            border: 0 solid #e5e7eb;
            box-sizing: border-box
        }
    
        :after, :before {
            --tw-content: ""
        }
    
        html {
            -webkit-text-size-adjust: 100%;
            font-feature-settings: normal;
            font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-variation-settings: normal;
            line-height: 1.5;
            tab-size: 4
        }
    
        body {
            line-height: inherit;
            margin: 0
        }
    
        hr {
            border-top-width: 1px;
            color: inherit;
            height: 0
        }
    
        abbr:where([title]) {
            -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted
        }
    
        h1, h2, h3, h4, h5, h6 {
            font-size: inherit;
            font-weight: inherit
        }
    
        a {
            color: inherit;
            text-decoration: inherit
        }
    
        b, strong {
            font-weight: bolder
        }
    
        code, kbd, pre, samp {
            font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
            font-size: 1em
        }
    
        small {
            font-size: 80%
        }
    
        sub, sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline
        }
    
        sub {
            bottom: -.25em
        }
    
        sup {
            top: -.5em
        }
    
        table {
            border-collapse: collapse;
            border-color: inherit;
            text-indent: 0
        }
    
        button, input, optgroup, select, textarea {
            color: inherit;
            font-family: inherit;
            font-size: 100%;
            font-weight: inherit;
            line-height: inherit;
            margin: 0;
            padding: 0
        }
    
        button, select {
            text-transform: none
        }
    
        [type=button], [type=reset], [type=submit], button {
            -webkit-appearance: button;
            background-color: transparent;
            background-image: none
        }
    
        :-moz-focusring {
            outline: auto
        }
    
        :-moz-ui-invalid {
            box-shadow: none
        }
    
        progress {
            vertical-align: baseline
        }
    
        ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
            height: auto
        }
    
        [type=search] {
            -webkit-appearance: textfield;
            outline-offset: -2px
        }
    
        ::-webkit-search-decoration {
            -webkit-appearance: none
        }
    
        ::-webkit-file-upload-button {
            -webkit-appearance: button;
            font: inherit
        }
    
        summary {
            display: list-item
        }
    
        blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
            margin: 0
        }
    
        fieldset {
            margin: 0
        }
    
        fieldset, legend {
            padding: 0
        }
    
        menu, ol, ul {
            list-style: none;
            margin: 0;
            padding: 0
        }
    
        textarea {
            resize: vertical
        }
    
        input::placeholder, textarea::placeholder {
            color: #9ca3af;
            opacity: 1
        }
    
        [role=button], button {
            cursor: pointer
        }
    
        :disabled {
            cursor: default
        }
    
        audio, canvas, embed, iframe, img, object, svg, video {
            display: block;
            vertical-align: middle
        }
    
        img, video {
            height: auto;
            max-width: 100%
        }
    
        [hidden] {
            display: none
        }
    
        *, :after, :before {
            --tw-border-spacing-x: 0;
            --tw-border-spacing-y: 0;
            --tw-translate-x: 0;
            --tw-translate-y: 0;
            --tw-rotate: 0;
            --tw-skew-x: 0;
            --tw-skew-y: 0;
            --tw-scale-x: 1;
            --tw-scale-y: 1;
            --tw-pan-x: ;
            --tw-pan-y: ;
            --tw-pinch-zoom: ;
            --tw-scroll-snap-strictness: proximity;
            --tw-gradient-from-position: ;
            --tw-gradient-via-position: ;
            --tw-gradient-to-position: ;
            --tw-ordinal: ;
            --tw-slashed-zero: ;
            --tw-numeric-figure: ;
            --tw-numeric-spacing: ;
            --tw-numeric-fraction: ;
            --tw-ring-inset: ;
            --tw-ring-offset-width: 0px;
            --tw-ring-offset-color: #fff;
            --tw-ring-color: rgba(59, 130, 246, .5);
            --tw-ring-offset-shadow: 0 0 #0000;
            --tw-ring-shadow: 0 0 #0000;
            --tw-shadow: 0 0 #0000;
            --tw-shadow-colored: 0 0 #0000;
            --tw-blur: ;
            --tw-brightness: ;
            --tw-contrast: ;
            --tw-grayscale: ;
            --tw-hue-rotate: ;
            --tw-invert: ;
            --tw-saturate: ;
            --tw-sepia: ;
            --tw-drop-shadow: ;
            --tw-backdrop-blur: ;
            --tw-backdrop-brightness: ;
            --tw-backdrop-contrast: ;
            --tw-backdrop-grayscale: ;
            --tw-backdrop-hue-rotate: ;
            --tw-backdrop-invert: ;
            --tw-backdrop-opacity: ;
            --tw-backdrop-saturate: ;
            --tw-backdrop-sepia:
        }
    
        ::backdrop {
            --tw-border-spacing-x: 0;
            --tw-border-spacing-y: 0;
            --tw-translate-x: 0;
            --tw-translate-y: 0;
            --tw-rotate: 0;
            --tw-skew-x: 0;
            --tw-skew-y: 0;
            --tw-scale-x: 1;
            --tw-scale-y: 1;
            --tw-pan-x: ;
            --tw-pan-y: ;
            --tw-pinch-zoom: ;
            --tw-scroll-snap-strictness: proximity;
            --tw-gradient-from-position: ;
            --tw-gradient-via-position: ;
            --tw-gradient-to-position: ;
            --tw-ordinal: ;
            --tw-slashed-zero: ;
            --tw-numeric-figure: ;
            --tw-numeric-spacing: ;
            --tw-numeric-fraction: ;
            --tw-ring-inset: ;
            --tw-ring-offset-width: 0px;
            --tw-ring-offset-color: #fff;
            --tw-ring-color: rgba(59, 130, 246, .5);
            --tw-ring-offset-shadow: 0 0 #0000;
            --tw-ring-shadow: 0 0 #0000;
            --tw-shadow: 0 0 #0000;
            --tw-shadow-colored: 0 0 #0000;
            --tw-blur: ;
            --tw-brightness: ;
            --tw-contrast: ;
            --tw-grayscale: ;
            --tw-hue-rotate: ;
            --tw-invert: ;
            --tw-saturate: ;
            --tw-sepia: ;
            --tw-drop-shadow: ;
            --tw-backdrop-blur: ;
            --tw-backdrop-brightness: ;
            --tw-backdrop-contrast: ;
            --tw-backdrop-grayscale: ;
            --tw-backdrop-hue-rotate: ;
            --tw-backdrop-invert: ;
            --tw-backdrop-opacity: ;
            --tw-backdrop-saturate: ;
            --tw-backdrop-sepia:
        }
    
        .container {
            width: 100%
        }
    
        @media (min-width: 640px) {
            .container {
                max-width: 640px
            }
        }
    
        @media (min-width: 768px) {
            .container {
                max-width: 768px
            }
        }
    
        @media (min-width: 1024px) {
            .container {
                max-width: 1024px
            }
        }
    
        @media (min-width: 1280px) {
            .container {
                max-width: 1280px
            }
        }
    
        @media (min-width: 1536px) {
            .container {
                max-width: 1536px
            }
        }
    
        .pointer-events-none {
            pointer-events: none
        }
    
        .fixed {
            position: fixed
        }
    
        .absolute {
            position: absolute
        }
    
        .relative {
            position: relative
        }
    
        .inset-0 {
            inset: 0
        }
    
        .-bottom-10 {
            bottom: -2.5rem
        }
    
        .-bottom-2 {
            bottom: -.5rem
        }
    
        .-bottom-3 {
            bottom: -.75rem
        }
    
        .-left-24 {
            left: -6rem
        }
    
        .-top-1/4 {
            top: -25%
        }
    
        .bottom-0 {
            bottom: 0
        }
    
        .left-3 {
            left: .75rem
        }
    
        .left-4 {
            left: 1rem
        }
    
        .left-40 {
            left: 10rem
        }
    
        .left-6 {
            left: 1.5rem
        }
    
        .left-8 {
            left: 2rem
        }
    
        .left-px {
            left: 1px
        }
    
        .right-0 {
            right: 0
        }
    
        .right-2 {
            right: .5rem
        }
    
        .right-4 {
            right: 1rem
        }
    
        .top-0 {
            top: 0
        }
    
        .top-10 {
            top: 2.5rem
        }
    
        .top-2 {
            top: .5rem
        }
    
        .top-2.5 {
            top: .625rem
        }
    
        .top-3 {
            top: .75rem
        }
    
        .top-4 {
            top: 1rem
        }
    
        .top-[19px] {
            top: 19px
        }
    
        .top-px {
            top: 1px
        }
    
        .isolate {
            isolation: isolate
        }
    
        .z-0 {
            z-index: 0
        }
    
        .z-10 {
            z-index: 10
        }
    
        .col-span-3 {
            grid-column: span 3/span 3
        }
    
        .col-span-5 {
            grid-column: span 5/span 5
        }
    
        .col-span-7 {
            grid-column: span 7/span 7
        }
    
        .col-start-3 {
            grid-column-start: 3
        }
    
        .col-start-7 {
            grid-column-start: 7
        }
    
        .float-left {
            float: left
        }
    
        .m-auto {
            margin: auto
        }
    
        .mx-1 {
            margin-left: .25rem;
            margin-right: .25rem
        }
    
        .mx-2 {
            margin-left: .5rem;
            margin-right: .5rem
        }
    
        .mx-2.5 {
            margin-left: .625rem;
            margin-right: .625rem
        }
    
        .mx-20 {
            margin-left: 5rem;
            margin-right: 5rem
        }
    
        .mx-3 {
            margin-left: .75rem;
            margin-right: .75rem
        }
    
        .mx-4 {
            margin-left: 1rem;
            margin-right: 1rem
        }
    
        .mx-5 {
            margin-left: 1.25rem;
            margin-right: 1.25rem
        }
    
        .mx-8 {
            margin-left: 2rem;
            margin-right: 2rem
        }
    
        .mx-auto {
            margin-left: auto;
            margin-right: auto
        }
    
        .my-0 {
            margin-bottom: 0;
            margin-top: 0
        }
    
        .my-0.5 {
            margin-bottom: .125rem;
            margin-top: .125rem
        }
    
        .my-10 {
            margin-bottom: 2.5rem;
            margin-top: 2.5rem
        }
    
        .my-2 {
            margin-bottom: .5rem;
            margin-top: .5rem
        }
    
        .my-2.5 {
            margin-bottom: .625rem;
            margin-top: .625rem
        }
    
        .my-3 {
            margin-bottom: .75rem;
            margin-top: .75rem
        }
    
        .my-4 {
            margin-bottom: 1rem;
            margin-top: 1rem
        }
    
        .my-5 {
            margin-bottom: 1.25rem;
            margin-top: 1.25rem
        }
    
        .my-6 {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    
        .my-8 {
            margin-bottom: 2rem;
            margin-top: 2rem
        }
    
        .-ml-40 {
            margin-left: -10rem
        }
    
        .-mt-1 {
            margin-top: -.25rem
        }
    
        .-mt-1.5 {
            margin-top: -.375rem
        }
    
        .-mt-40 {
            margin-top: -10rem
        }
    
        .mb-0 {
            margin-bottom: 0
        }
    
        .mb-1 {
            margin-bottom: .25rem
        }
    
        .mb-10 {
            margin-bottom: 2.5rem
        }
    
        .mb-11 {
            margin-bottom: 2.75rem
        }
    
        .mb-12 {
            margin-bottom: 3rem
        }
    
        .mb-14 {
            margin-bottom: 3.5rem
        }
    
        .mb-2 {
            margin-bottom: .5rem
        }
    
        .mb-20 {
            margin-bottom: 5rem
        }
    
        .mb-24 {
            margin-bottom: 6rem
        }
    
        .mb-28 {
            margin-bottom: 7rem
        }
    
        .mb-3 {
            margin-bottom: .75rem
        }
    
        .mb-4 {
            margin-bottom: 1rem
        }
    
        .mb-5 {
            margin-bottom: 1.25rem
        }
    
        .mb-6 {
            margin-bottom: 1.5rem
        }
    
        .mb-7 {
            margin-bottom: 1.75rem
        }
    
        .mb-8 {
            margin-bottom: 2rem
        }
    
        .ml-1 {
            margin-left: .25rem
        }
    
        .ml-12 {
            margin-left: 3rem
        }
    
        .ml-16 {
            margin-left: 4rem
        }
    
        .ml-2 {
            margin-left: .5rem
        }
    
        .ml-4 {
            margin-left: 1rem
        }
    
        .ml-40 {
            margin-left: 10rem
        }
    
        .ml-auto {
            margin-left: auto
        }
    
        .mr-0 {
            margin-right: 0
        }
    
        .mr-0.5 {
            margin-right: .125rem
        }
    
        .mr-1 {
            margin-right: .25rem
        }
    
        .mr-2 {
            margin-right: .5rem
        }
    
        .mr-24 {
            margin-right: 6rem
        }
    
        .mr-3 {
            margin-right: .75rem
        }
    
        .mt-1 {
            margin-top: .25rem
        }
    
        .mt-1.5 {
            margin-top: .375rem
        }
    
        .mt-10 {
            margin-top: 2.5rem
        }
    
        .mt-12 {
            margin-top: 3rem
        }
    
        .mt-14 {
            margin-top: 3.5rem
        }
    
        .mt-16 {
            margin-top: 4rem
        }
    
        .mt-2 {
            margin-top: .5rem
        }
    
        .mt-2.5 {
            margin-top: .625rem
        }
    
        .mt-20 {
            margin-top: 5rem
        }
    
        .mt-24 {
            margin-top: 6rem
        }
    
        .mt-3 {
            margin-top: .75rem
        }
    
        .mt-4 {
            margin-top: 1rem
        }
    
        .mt-5 {
            margin-top: 1.25rem
        }
    
        .mt-6 {
            margin-top: 1.5rem
        }
    
        .mt-7 {
            margin-top: 1.75rem
        }
    
        .mt-72 {
            margin-top: 18rem
        }
    
        .mt-8 {
            margin-top: 2rem
        }
    
        .mt-auto {
            margin-top: auto
        }
    
        .block {
            display: block
        }
    
        .inline-block {
            display: inline-block
        }
    
        .flex {
            display: flex
        }
    
        .inline-flex {
            display: inline-flex
        }
    
        .table {
            display: table
        }
    
        .table-row {
            display: table-row
        }
    
        .grid {
            display: grid
        }
    
        .hidden {
            display: none
        }
    
        .h-1/2 {
            height: 50%
        }
    
        .h-10 {
            height: 2.5rem
        }
    
        .h-11 {
            height: 2.75rem
        }
    
        .h-12 {
            height: 3rem
        }
    
        .h-14 {
            height: 3.5rem
        }
    
        .h-16 {
            height: 4rem
        }
    
        .h-2 {
            height: .5rem
        }
    
        .h-20 {
            height: 5rem
        }
    
        .h-28 {
            height: 7rem
        }
    
        .h-3 {
            height: .75rem
        }
    
        .h-3.5 {
            height: .875rem
        }
    
        .h-3/4 {
            height: 75%
        }
    
        .h-32 {
            height: 8rem
        }
    
        .h-36 {
            height: 9rem
        }
    
        .h-4 {
            height: 1rem
        }
    
        .h-44 {
            height: 11rem
        }
    
        .h-5 {
            height: 1.25rem
        }
    
        .h-6 {
            height: 1.5rem
        }
    
        .h-7 {
            height: 1.75rem
        }
    
        .h-8 {
            height: 2rem
        }
    
        .h-9 {
            height: 2.25rem
        }
    
        .h-[282px] {
            height: 282px
        }
    
        .h-[60px] {
            height: 60px
        }
    
        .h-[64px] {
            height: 64px
        }
    
        .h-auto {
            height: auto
        }
    
        .h-full {
            height: 100%
        }
    
        .h-px {
            height: 1px
        }
    
        .h-screen {
            height: 100vh
        }
    
        .max-h-20 {
            max-height: 5rem
        }
    
        .min-h-full {
            min-height: 100%
        }
    
        .min-h-screen {
            min-height: 100vh
        }
    
        .w-1/2 {
            width: 50%
        }
    
        .w-1/4 {
            width: 25%
        }
    
        .w-1/5 {
            width: 20%
        }
    
        .w-10 {
            width: 2.5rem
        }
    
        .w-12 {
            width: 3rem
        }
    
        .w-20 {
            width: 5rem
        }
    
        .w-24 {
            width: 6rem
        }
    
        .w-3 {
            width: .75rem
        }
    
        .w-3.5 {
            width: .875rem
        }
    
        .w-32 {
            width: 8rem
        }
    
        .w-36 {
            width: 9rem
        }
    
        .w-4 {
            width: 1rem
        }
    
        .w-40 {
            width: 10rem
        }
    
        .w-44 {
            width: 11rem
        }
    
        .w-48 {
            width: 12rem
        }
    
        .w-5 {
            width: 1.25rem
        }
    
        .w-6 {
            width: 1.5rem
        }
    
        .w-7 {
            width: 1.75rem
        }
    
        .w-72 {
            width: 18rem
        }
    
        .w-8 {
            width: 2rem
        }
    
        .w-80 {
            width: 20rem
        }
    
        .w-96 {
            width: 24rem
        }
    
        .w-[200px] {
            width: 200px
        }
    
        .w-[251px] {
            width: 251px
        }
    
        .w-[300px] {
            width: 300px
        }
    
        .w-auto {
            width: auto
        }
    
        .w-full {
            width: 100%
        }
    
        .w-screen {
            width: 100vw
        }
    
        .min-w-full {
            min-width: 100%
        }
    
        .max-w-2xl {
            max-width: 42rem
        }
    
        .max-w-400 {
            max-width: 400px
        }
    
        .max-w-460 {
            max-width: 460px
        }
    
        .max-w-4xl {
            max-width: 56rem
        }
    
        .max-w-6xl {
            max-width: 72rem
        }
    
        .max-w-7xl {
            max-width: 80rem
        }
    
        .max-w-[150px] {
            max-width: 150px
        }
    
        .max-w-[528px] {
            max-width: 528px
        }
    
        .max-w-lg {
            max-width: 32rem
        }
    
        .max-w-sm {
            max-width: 24rem
        }
    
        .max-w-xs {
            max-width: 20rem
        }
    
        .flex-1 {
            flex: 1 1 0%
        }
    
        .flex-shrink {
            flex-shrink: 1
        }
    
        .flex-shrink-0 {
            flex-shrink: 0
        }
    
        .flex-grow {
            flex-grow: 1
        }
    
        .transform {
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
        }
    
        .cursor-auto {
            cursor: auto
        }
    
        .cursor-pointer {
            cursor: pointer
        }
    
        .cursor-text {
            cursor: text
        }
    
        .select-none {
            -webkit-user-select: none;
            user-select: none
        }
    
        .resize-none {
            resize: none
        }
    
        .resize {
            resize: both
        }
    
        .list-disc {
            list-style-type: disc
        }
    
        .appearance-none {
            -webkit-appearance: none;
            appearance: none
        }
    
        .grid-cols-12 {
            grid-template-columns:repeat(12, minmax(0, 1fr))
        }
    
        .flex-col {
            flex-direction: column
        }
    
        .flex-wrap {
            flex-wrap: wrap
        }
    
        .items-start {
            align-items: flex-start
        }
    
        .items-center {
            align-items: center
        }
    
        .justify-start {
            justify-content: flex-start
        }
    
        .justify-end {
            justify-content: flex-end
        }
    
        .justify-center {
            justify-content: center
        }
    
        .justify-between {
            justify-content: space-between
        }
    
        .justify-around {
            justify-content: space-around
        }
    
        .justify-evenly {
            justify-content: space-evenly
        }
    
        .gap-x-1 {
            column-gap: .25rem
        }
    
        .gap-x-2 {
            column-gap: .5rem
        }
    
        .space-x-1 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: .25rem;
            margin-left: calc(.25rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(.25rem * var(--tw-space-x-reverse))
        }
    
        .space-x-1.5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: .375rem;
            margin-left: calc(.375rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(.375rem * var(--tw-space-x-reverse))
        }
    
        .space-x-2 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: .5rem;
            margin-left: calc(.5rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(.5rem * var(--tw-space-x-reverse))
        }
    
        .space-x-3 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: .75rem;
            margin-left: calc(.75rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(.75rem * var(--tw-space-x-reverse))
        }
    
        .space-x-4 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: 1rem;
            margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(1rem * var(--tw-space-x-reverse))
        }
    
        .space-x-5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-left: 1.25rem;
            margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
            margin-right: 0;
            margin-right: calc(1.25rem * var(--tw-space-x-reverse))
        }
    
        .space-y-1 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
            margin-top: .25rem;
            margin-top: calc(.25rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-1.5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
            margin-top: .375rem;
            margin-top: calc(.375rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-10 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
            margin-top: 2.5rem;
            margin-top: calc(2.5rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-2 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
            margin-top: .5rem;
            margin-top: calc(.5rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-2.5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.625rem * var(--tw-space-y-reverse));
            margin-top: .625rem;
            margin-top: calc(.625rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-3 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
            margin-top: .75rem;
            margin-top: calc(.75rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-3.5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(.875rem * var(--tw-space-y-reverse));
            margin-top: .875rem;
            margin-top: calc(.875rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-4 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(1rem * var(--tw-space-y-reverse));
            margin-top: 1rem;
            margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-5 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
            margin-top: 1.25rem;
            margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .space-y-6 > :not([hidden]) ~ :not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-bottom: 0;
            margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
            margin-top: 1.5rem;
            margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)))
        }
    
        .self-start {
            align-self: flex-start
        }
    
        .self-center {
            align-self: center
        }
    
        .self-stretch {
            align-self: stretch
        }
    
        .overflow-auto {
            overflow: auto
        }
    
        .overflow-hidden {
            overflow: hidden
        }
    
        .whitespace-nowrap {
            white-space: nowrap
        }
    
        .rounded {
            border-radius: .25rem
        }
    
        .rounded-2xl {
            border-radius: 1rem
        }
    
        .rounded-3 {
            border-radius: 3px
        }
    
        .rounded-3xl {
            border-radius: 1.5rem
        }
    
        .rounded-[10px] {
            border-radius: 10px
        }
    
        .rounded-full {
            border-radius: 9999px
        }
    
        .rounded-lg {
            border-radius: .5rem
        }
    
        .rounded-md {
            border-radius: .375rem
        }
    
        .rounded-sm {
            border-radius: .125rem
        }
    
        .rounded-xl {
            border-radius: .75rem
        }
    
        .rounded-b-md {
            border-bottom-left-radius: .375rem;
            border-bottom-right-radius: .375rem
        }
    
        .rounded-l-3 {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px
        }
    
        .rounded-l-md {
            border-bottom-left-radius: .375rem
        }
    
        .rounded-l-md, .rounded-t-md {
            border-top-left-radius: .375rem
        }
    
        .rounded-t-md {
            border-top-right-radius: .375rem
        }
    
        .border {
            border-width: 1px
        }
    
        .border-2 {
            border-width: 2px
        }
    
        .border-3 {
            border-width: 3px
        }
    
        .border-4 {
            border-width: 4px
        }
    
        .border-y {
            border-top-width: 1px
        }
    
        .border-b, .border-y {
            border-bottom-width: 1px
        }
    
        .border-b-2 {
            border-bottom-width: 2px
        }
    
        .border-l {
            border-left-width: 1px
        }
    
        .border-l-2 {
            border-left-width: 2px
        }
    
        .border-r {
            border-right-width: 1px
        }
    
        .border-t {
            border-top-width: 1px
        }
    
        .border-t-0 {
            border-top-width: 0
        }
    
        .border-t-[6px] {
            border-top-width: 6px
        }
    
        .border-solid {
            border-style: solid
        }
    
        .border-none {
            border-style: none
        }
    
        .border-[#949494] {
            --tw-border-opacity: 1;
            border-color: #949494;
            border-color: rgb(148 148 148/var(--tw-border-opacity))
        }
    
        .border-[#ffb713] {
            --tw-border-opacity: 1;
            border-color: #ffb713;
            border-color: rgb(255 183 19/var(--tw-border-opacity))
        }
    
        .border-black {
            --tw-border-opacity: 1;
            border-color: #000;
            border-color: rgb(0 0 0/var(--tw-border-opacity))
        }
    
        .border-blue-500 {
            --tw-border-opacity: 1;
            border-color: #3b82f6;
            border-color: rgb(59 130 246/var(--tw-border-opacity))
        }
    
        .border-blue-600 {
            --tw-border-opacity: 1;
            border-color: #2563eb;
            border-color: rgb(37 99 235/var(--tw-border-opacity))
        }
    
        .border-blueGray-200 {
            --tw-border-opacity: 1;
            border-color: #e2e8f0;
            border-color: rgb(226 232 240/var(--tw-border-opacity))
        }
    
        .border-blueGray-300 {
            --tw-border-opacity: 1;
            border-color: #cbd5e1;
            border-color: rgb(203 213 225/var(--tw-border-opacity))
        }
    
        .border-gray-100 {
            --tw-border-opacity: 1;
            border-color: #f3f4f6;
            border-color: rgb(243 244 246/var(--tw-border-opacity))
        }
    
        .border-gray-200 {
            --tw-border-opacity: 1;
            border-color: #e5e7eb;
            border-color: rgb(229 231 235/var(--tw-border-opacity))
        }
    
        .border-gray-300 {
            --tw-border-opacity: 1;
            border-color: #d1d5db;
            border-color: rgb(209 213 219/var(--tw-border-opacity))
        }
    
        .border-gray-400 {
            --tw-border-opacity: 1;
            border-color: #9ca3af;
            border-color: rgb(156 163 175/var(--tw-border-opacity))
        }
    
        .border-gray-600 {
            --tw-border-opacity: 1;
            border-color: #4b5563;
            border-color: rgb(75 85 99/var(--tw-border-opacity))
        }
    
        .border-gray-700 {
            --tw-border-opacity: 1;
            border-color: #374151;
            border-color: rgb(55 65 81/var(--tw-border-opacity))
        }
    
        .border-gray-800 {
            --tw-border-opacity: 1;
            border-color: #1f2937;
            border-color: rgb(31 41 55/var(--tw-border-opacity))
        }
    
        .border-gray-900 {
            --tw-border-opacity: 1;
            border-color: #111827;
            border-color: rgb(17 24 39/var(--tw-border-opacity))
        }
    
        .border-lightBlue-700 {
            --tw-border-opacity: 1;
            border-color: #0369a1;
            border-color: rgb(3 105 161/var(--tw-border-opacity))
        }
    
        .border-transparent {
            border-color: transparent
        }
    
        .border-trueGray-200 {
            --tw-border-opacity: 1;
            border-color: #e5e5e5;
            border-color: rgb(229 229 229/var(--tw-border-opacity))
        }
    
        .border-trueGray-300 {
            --tw-border-opacity: 1;
            border-color: #d4d4d4;
            border-color: rgb(212 212 212/var(--tw-border-opacity))
        }
    
        .border-trueGray-400 {
            --tw-border-opacity: 1;
            border-color: #a3a3a3;
            border-color: rgb(163 163 163/var(--tw-border-opacity))
        }
    
        .border-trueGray-500 {
            --tw-border-opacity: 1;
            border-color: #737373;
            border-color: rgb(115 115 115/var(--tw-border-opacity))
        }
    
        .border-warmGray-300 {
            --tw-border-opacity: 1;
            border-color: #d6d3d1;
            border-color: rgb(214 211 209/var(--tw-border-opacity))
        }
    
        .border-warmGray-700 {
            --tw-border-opacity: 1;
            border-color: #44403c;
            border-color: rgb(68 64 60/var(--tw-border-opacity))
        }
    
        .border-white {
            --tw-border-opacity: 1;
            border-color: #fff;
            border-color: rgb(255 255 255/var(--tw-border-opacity))
        }
    
        .border-yellow-400 {
            --tw-border-opacity: 1;
            border-color: #facc15;
            border-color: rgb(250 204 21/var(--tw-border-opacity))
        }
    
        .border-opacity-10 {
            --tw-border-opacity: .1
        }
    
        .border-opacity-60 {
            --tw-border-opacity: .6
        }
    
        .border-opacity-80 {
            --tw-border-opacity: .8
        }
    
        .bg-[#ffb713] {
            --tw-bg-opacity: 1;
            background-color: #ffb713;
            background-color: rgb(255 183 19/var(--tw-bg-opacity))
        }
    
        .bg-black {
            --tw-bg-opacity: 1;
            background-color: #000;
            background-color: rgb(0 0 0/var(--tw-bg-opacity))
        }
    
        .bg-blue-200 {
            --tw-bg-opacity: 1;
            background-color: #bfdbfe;
            background-color: rgb(191 219 254/var(--tw-bg-opacity))
        }
    
        .bg-blue-500 {
            --tw-bg-opacity: 1;
            background-color: #3b82f6;
            background-color: rgb(59 130 246/var(--tw-bg-opacity))
        }
    
        .bg-blue-600 {
            --tw-bg-opacity: 1;
            background-color: #2563eb;
            background-color: rgb(37 99 235/var(--tw-bg-opacity))
        }
    
        .bg-blue-700 {
            --tw-bg-opacity: 1;
            background-color: #1d4ed8;
            background-color: rgb(29 78 216/var(--tw-bg-opacity))
        }
    
        .bg-blueGray-100 {
            --tw-bg-opacity: 1;
            background-color: #f1f5f9;
            background-color: rgb(241 245 249/var(--tw-bg-opacity))
        }
    
        .bg-blueGray-50 {
            --tw-bg-opacity: 1;
            background-color: #f8fafc;
            background-color: rgb(248 250 252/var(--tw-bg-opacity))
        }
    
        .bg-gray-100 {
            --tw-bg-opacity: 1;
            background-color: #f3f4f6;
            background-color: rgb(243 244 246/var(--tw-bg-opacity))
        }
    
        .bg-gray-200 {
            --tw-bg-opacity: 1;
            background-color: #e5e7eb;
            background-color: rgb(229 231 235/var(--tw-bg-opacity))
        }
    
        .bg-gray-300 {
            --tw-bg-opacity: 1;
            background-color: #d1d5db;
            background-color: rgb(209 213 219/var(--tw-bg-opacity))
        }
    
        .bg-gray-50 {
            --tw-bg-opacity: 1;
            background-color: #f9fafb;
            background-color: rgb(249 250 251/var(--tw-bg-opacity))
        }
    
        .bg-gray-800 {
            --tw-bg-opacity: 1;
            background-color: #1f2937;
            background-color: rgb(31 41 55/var(--tw-bg-opacity))
        }
    
        .bg-lightBlue-600 {
            --tw-bg-opacity: 1;
            background-color: #0284c7;
            background-color: rgb(2 132 199/var(--tw-bg-opacity))
        }
    
        .bg-lightBlue-700 {
            --tw-bg-opacity: 1;
            background-color: #0369a1;
            background-color: rgb(3 105 161/var(--tw-bg-opacity))
        }
    
        .bg-swile {
            --tw-bg-opacity: 1;
            background-color: #1d1148;
            background-color: rgb(29 17 72/var(--tw-bg-opacity))
        }
    
        .bg-transparent {
            background-color: transparent
        }
    
        .bg-trueGray-100 {
            --tw-bg-opacity: 1;
            background-color: #f5f5f5;
            background-color: rgb(245 245 245/var(--tw-bg-opacity))
        }
    
        .bg-trueGray-50 {
            --tw-bg-opacity: 1;
            background-color: #fafafa;
            background-color: rgb(250 250 250/var(--tw-bg-opacity))
        }
    
        .bg-white {
            --tw-bg-opacity: 1;
            background-color: #fff;
            background-color: rgb(255 255 255/var(--tw-bg-opacity))
        }
    
        .bg-yellow-400 {
            --tw-bg-opacity: 1;
            background-color: #facc15;
            background-color: rgb(250 204 21/var(--tw-bg-opacity))
        }
    
        .bg-opacity-10 {
            --tw-bg-opacity: .1
        }
    
        .bg-opacity-40 {
            --tw-bg-opacity: .4
        }
    
        .bg-opacity-60 {
            --tw-bg-opacity: .6
        }
    
        .bg-opacity-70 {
            --tw-bg-opacity: .7
        }
    
        .bg-cover {
            background-size: cover
        }
    
        .bg-center {
            background-position: 50%
        }
    
        .bg-no-repeat {
            background-repeat: no-repeat
        }
    
        .fill-current {
            fill: currentColor
        }
    
        .object-cover {
            object-fit: cover
        }
    
        .p-0 {
            padding: 0
        }
    
        .p-1 {
            padding: .25rem
        }
    
        .p-1.5 {
            padding: .375rem
        }
    
        .p-10 {
            padding: 2.5rem
        }
    
        .p-14 {
            padding: 3.5rem
        }
    
        .p-2 {
            padding: .5rem
        }
    
        .p-2.5 {
            padding: .625rem
        }
    
        .p-3 {
            padding: .75rem
        }
    
        .p-4 {
            padding: 1rem
        }
    
        .p-5 {
            padding: 1.25rem
        }
    
        .p-6 {
            padding: 1.5rem
        }
    
        .p-8 {
            padding: 2rem
        }
    
        .px-1 {
            padding-left: .25rem;
            padding-right: .25rem
        }
    
        .px-10 {
            padding-left: 2.5rem;
            padding-right: 2.5rem
        }
    
        .px-11 {
            padding-left: 2.75rem;
            padding-right: 2.75rem
        }
    
        .px-14 {
            padding-left: 3.5rem;
            padding-right: 3.5rem
        }
    
        .px-16 {
            padding-left: 4rem;
            padding-right: 4rem
        }
    
        .px-2 {
            padding-left: .5rem;
            padding-right: .5rem
        }
    
        .px-2.5 {
            padding-left: .625rem;
            padding-right: .625rem
        }
    
        .px-20 {
            padding-left: 5rem;
            padding-right: 5rem
        }
    
        .px-3 {
            padding-left: .75rem;
            padding-right: .75rem
        }
    
        .px-3.5 {
            padding-left: .875rem;
            padding-right: .875rem
        }
    
        .px-4 {
            padding-left: 1rem;
            padding-right: 1rem
        }
    
        .px-40 {
            padding-left: 10rem;
            padding-right: 10rem
        }
    
        .px-5 {
            padding-left: 1.25rem;
            padding-right: 1.25rem
        }
    
        .px-6 {
            padding-left: 1.5rem;
            padding-right: 1.5rem
        }
    
        .px-7 {
            padding-left: 1.75rem;
            padding-right: 1.75rem
        }
    
        .px-8 {
            padding-left: 2rem;
            padding-right: 2rem
        }
    
        .py-0 {
            padding-bottom: 0;
            padding-top: 0
        }
    
        .py-0.5 {
            padding-bottom: .125rem;
            padding-top: .125rem
        }
    
        .py-1 {
            padding-bottom: .25rem;
            padding-top: .25rem
        }
    
        .py-1.5 {
            padding-bottom: .375rem;
            padding-top: .375rem
        }
    
        .py-10 {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    
        .py-12 {
            padding-bottom: 3rem;
            padding-top: 3rem
        }
    
        .py-14 {
            padding-bottom: 3.5rem;
            padding-top: 3.5rem
        }
    
        .py-2 {
            padding-bottom: .5rem;
            padding-top: .5rem
        }
    
        .py-2.5 {
            padding-bottom: .625rem;
            padding-top: .625rem
        }
    
        .py-3 {
            padding-bottom: .75rem;
            padding-top: .75rem
        }
    
        .py-3.5 {
            padding-bottom: .875rem;
            padding-top: .875rem
        }
    
        .py-4 {
            padding-bottom: 1rem;
            padding-top: 1rem
        }
    
        .py-5 {
            padding-bottom: 1.25rem;
            padding-top: 1.25rem
        }
    
        .py-6 {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    
        .py-7 {
            padding-bottom: 1.75rem;
            padding-top: 1.75rem
        }
    
        .py-8 {
            padding-bottom: 2rem;
            padding-top: 2rem
        }
    
        .py-[13px] {
            padding-bottom: 13px;
            padding-top: 13px
        }
    
        .pb-1 {
            padding-bottom: .25rem
        }
    
        .pb-10 {
            padding-bottom: 2.5rem
        }
    
        .pb-11 {
            padding-bottom: 2.75rem
        }
    
        .pb-12 {
            padding-bottom: 3rem
        }
    
        .pb-14 {
            padding-bottom: 3.5rem
        }
    
        .pb-2 {
            padding-bottom: .5rem
        }
    
        .pb-20 {
            padding-bottom: 5rem
        }
    
        .pb-24 {
            padding-bottom: 6rem
        }
    
        .pb-3 {
            padding-bottom: .75rem
        }
    
        .pb-3.5 {
            padding-bottom: .875rem
        }
    
        .pb-4 {
            padding-bottom: 1rem
        }
    
        .pb-5 {
            padding-bottom: 1.25rem
        }
    
        .pb-6 {
            padding-bottom: 1.5rem
        }
    
        .pb-8 {
            padding-bottom: 2rem
        }
    
        .pl-1 {
            padding-left: .25rem
        }
    
        .pl-10 {
            padding-left: 2.5rem
        }
    
        .pl-2 {
            padding-left: .5rem
        }
    
        .pl-20 {
            padding-left: 5rem
        }
    
        .pl-3 {
            padding-left: .75rem
        }
    
        .pl-4 {
            padding-left: 1rem
        }
    
        .pl-5 {
            padding-left: 1.25rem
        }
    
        .pl-8 {
            padding-left: 2rem
        }
    
        .pr-14 {
            padding-right: 3.5rem
        }
    
        .pr-2 {
            padding-right: .5rem
        }
    
        .pr-2.5 {
            padding-right: .625rem
        }
    
        .pr-20 {
            padding-right: 5rem
        }
    
        .pr-4 {
            padding-right: 1rem
        }
    
        .pt-1 {
            padding-top: .25rem
        }
    
        .pt-10 {
            padding-top: 2.5rem
        }
    
        .pt-11 {
            padding-top: 2.75rem
        }
    
        .pt-12 {
            padding-top: 3rem
        }
    
        .pt-14 {
            padding-top: 3.5rem
        }
    
        .pt-16 {
            padding-top: 4rem
        }
    
        .pt-2 {
            padding-top: .5rem
        }
    
        .pt-2.5 {
            padding-top: .625rem
        }
    
        .pt-20 {
            padding-top: 5rem
        }
    
        .pt-24 {
            padding-top: 6rem
        }
    
        .pt-3 {
            padding-top: .75rem
        }
    
        .pt-36 {
            padding-top: 9rem
        }
    
        .pt-4 {
            padding-top: 1rem
        }
    
        .pt-40 {
            padding-top: 10rem
        }
    
        .pt-44 {
            padding-top: 11rem
        }
    
        .pt-5 {
            padding-top: 1.25rem
        }
    
        .pt-6 {
            padding-top: 1.5rem
        }
    
        .pt-7 {
            padding-top: 1.75rem
        }
    
        .pt-8 {
            padding-top: 2rem
        }
    
        .pt-9 {
            padding-top: 2.25rem
        }
    
        .text-center {
            text-align: center
        }
    
        .font-sans {
            font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
        }
    
        .text-2xl {
            font-size: 1.5rem;
            line-height: 2rem
        }
    
        .text-3xl {
            font-size: 1.875rem;
            line-height: 2.25rem
        }
    
        .text-4xl {
            font-size: 2.25rem;
            line-height: 2.5rem
        }
    
        .text-5xl {
            font-size: 3rem;
            line-height: 1
        }
    
        .text-base {
            font-size: 1rem;
            line-height: 1.5rem
        }
    
        .text-lg {
            font-size: 1.125rem;
            line-height: 1.75rem
        }
    
        .text-sm {
            font-size: .875rem;
            line-height: 1.25rem
        }
    
        .text-tiny {
            font-size: .8125rem
        }
    
        .text-xl {
            font-size: 1.25rem;
            line-height: 1.75rem
        }
    
        .text-xs {
            font-size: .75rem;
            line-height: 1rem
        }
    
        .font-black {
            font-weight: 900
        }
    
        .font-bold {
            font-weight: 700
        }
    
        .font-extrabold {
            font-weight: 800
        }
    
        .font-extralight {
            font-weight: 200
        }
    
        .font-light {
            font-weight: 300
        }
    
        .font-medium {
            font-weight: 500
        }
    
        .font-normal {
            font-weight: 400
        }
    
        .font-semibold {
            font-weight: 600
        }
    
        .uppercase {
            text-transform: uppercase
        }
    
        .leading-4 {
            line-height: 1rem
        }
    
        .leading-5 {
            line-height: 1.25rem
        }
    
        .leading-6 {
            line-height: 1.5rem
        }
    
        .leading-7 {
            line-height: 1.75rem
        }
    
        .leading-none {
            line-height: 1
        }
    
        .leading-relaxed {
            line-height: 1.625
        }
    
        .leading-tight {
            line-height: 1.25
        }
    
        .tracking-tight {
            letter-spacing: -.025em
        }
    
        .tracking-wide {
            letter-spacing: .025em
        }
    
        .tracking-wider {
            letter-spacing: .05em
        }
    
        .text-[#096cc7] {
            --tw-text-opacity: 1;
            color: #096cc7;
            color: rgb(9 108 199/var(--tw-text-opacity))
        }
    
        .text-[#1c1c1c] {
            --tw-text-opacity: 1;
            color: #1c1c1c;
            color: rgb(28 28 28/var(--tw-text-opacity))
        }
    
        .text-[#3c3c3c] {
            --tw-text-opacity: 1;
            color: #3c3c3c;
            color: rgb(60 60 60/var(--tw-text-opacity))
        }
    
        .text-[#4c4c4c] {
            --tw-text-opacity: 1;
            color: #4c4c4c;
            color: rgb(76 76 76/var(--tw-text-opacity))
        }
    
        .text-black {
            --tw-text-opacity: 1;
            color: #000;
            color: rgb(0 0 0/var(--tw-text-opacity))
        }
    
        .text-blue-500 {
            --tw-text-opacity: 1;
            color: #3b82f6;
            color: rgb(59 130 246/var(--tw-text-opacity))
        }
    
        .text-blue-600 {
            --tw-text-opacity: 1;
            color: #2563eb;
            color: rgb(37 99 235/var(--tw-text-opacity))
        }
    
        .text-blue-700 {
            --tw-text-opacity: 1;
            color: #1d4ed8;
            color: rgb(29 78 216/var(--tw-text-opacity))
        }
    
        .text-blue-800 {
            --tw-text-opacity: 1;
            color: #1e40af;
            color: rgb(30 64 175/var(--tw-text-opacity))
        }
    
        .text-blueGray-400 {
            --tw-text-opacity: 1;
            color: #94a3b8;
            color: rgb(148 163 184/var(--tw-text-opacity))
        }
    
        .text-blueGray-500 {
            --tw-text-opacity: 1;
            color: #64748b;
            color: rgb(100 116 139/var(--tw-text-opacity))
        }
    
        .text-blueGray-600 {
            --tw-text-opacity: 1;
            color: #475569;
            color: rgb(71 85 105/var(--tw-text-opacity))
        }
    
        .text-blueGray-700 {
            --tw-text-opacity: 1;
            color: #334155;
            color: rgb(51 65 85/var(--tw-text-opacity))
        }
    
        .text-blueGray-800 {
            --tw-text-opacity: 1;
            color: #1e293b;
            color: rgb(30 41 59/var(--tw-text-opacity))
        }
    
        .text-current {
            color: currentColor
        }
    
        .text-cyan-900 {
            --tw-text-opacity: 1;
            color: #164e63;
            color: rgb(22 78 99/var(--tw-text-opacity))
        }
    
        .text-gray-300 {
            --tw-text-opacity: 1;
            color: #d1d5db;
            color: rgb(209 213 219/var(--tw-text-opacity))
        }
    
        .text-gray-400 {
            --tw-text-opacity: 1;
            color: #9ca3af;
            color: rgb(156 163 175/var(--tw-text-opacity))
        }
    
        .text-gray-50 {
            --tw-text-opacity: 1;
            color: #f9fafb;
            color: rgb(249 250 251/var(--tw-text-opacity))
        }
    
        .text-gray-500 {
            --tw-text-opacity: 1;
            color: #6b7280;
            color: rgb(107 114 128/var(--tw-text-opacity))
        }
    
        .text-gray-600 {
            --tw-text-opacity: 1;
            color: #4b5563;
            color: rgb(75 85 99/var(--tw-text-opacity))
        }
    
        .text-gray-700 {
            --tw-text-opacity: 1;
            color: #374151;
            color: rgb(55 65 81/var(--tw-text-opacity))
        }
    
        .text-gray-800 {
            --tw-text-opacity: 1;
            color: #1f2937;
            color: rgb(31 41 55/var(--tw-text-opacity))
        }
    
        .text-gray-900 {
            --tw-text-opacity: 1;
            color: #111827;
            color: rgb(17 24 39/var(--tw-text-opacity))
        }
    
        .text-lightBlue-600 {
            --tw-text-opacity: 1;
            color: #0284c7;
            color: rgb(2 132 199/var(--tw-text-opacity))
        }
    
        .text-lightBlue-700 {
            --tw-text-opacity: 1;
            color: #0369a1;
            color: rgb(3 105 161/var(--tw-text-opacity))
        }
    
        .text-lightBlue-900 {
            --tw-text-opacity: 1;
            color: #0c4a6e;
            color: rgb(12 74 110/var(--tw-text-opacity))
        }
    
        .text-purple-700 {
            --tw-text-opacity: 1;
            color: #7e22ce;
            color: rgb(126 34 206/var(--tw-text-opacity))
        }
    
        .text-trueGray-300 {
            --tw-text-opacity: 1;
            color: #d4d4d4;
            color: rgb(212 212 212/var(--tw-text-opacity))
        }
    
        .text-trueGray-400 {
            --tw-text-opacity: 1;
            color: #a3a3a3;
            color: rgb(163 163 163/var(--tw-text-opacity))
        }
    
        .text-trueGray-500 {
            --tw-text-opacity: 1;
            color: #737373;
            color: rgb(115 115 115/var(--tw-text-opacity))
        }
    
        .text-trueGray-600 {
            --tw-text-opacity: 1;
            color: #525252;
            color: rgb(82 82 82/var(--tw-text-opacity))
        }
    
        .text-trueGray-700 {
            --tw-text-opacity: 1;
            color: #404040;
            color: rgb(64 64 64/var(--tw-text-opacity))
        }
    
        .text-trueGray-800 {
            --tw-text-opacity: 1;
            color: #262626;
            color: rgb(38 38 38/var(--tw-text-opacity))
        }
    
        .text-trueGray-900 {
            --tw-text-opacity: 1;
            color: #171717;
            color: rgb(23 23 23/var(--tw-text-opacity))
        }
    
        .text-warmGray-300 {
            --tw-text-opacity: 1;
            color: #d6d3d1;
            color: rgb(214 211 209/var(--tw-text-opacity))
        }
    
        .text-warmGray-500 {
            --tw-text-opacity: 1;
            color: #78716c;
            color: rgb(120 113 108/var(--tw-text-opacity))
        }
    
        .text-warmGray-700 {
            --tw-text-opacity: 1;
            color: #44403c;
            color: rgb(68 64 60/var(--tw-text-opacity))
        }
    
        .text-white {
            --tw-text-opacity: 1;
            color: #fff;
            color: rgb(255 255 255/var(--tw-text-opacity))
        }
    
        .underline {
            text-decoration-line: underline
        }
    
        .placeholder-gray-400::placeholder {
            --tw-placeholder-opacity: 1;
            color: #9ca3af;
            color: rgb(156 163 175/var(--tw-placeholder-opacity))
        }
    
        .placeholder-gray-500::placeholder {
            --tw-placeholder-opacity: 1;
            color: #6b7280;
            color: rgb(107 114 128/var(--tw-placeholder-opacity))
        }
    
        .opacity-20 {
            opacity: .2
        }
    
        .opacity-50 {
            opacity: .5
        }
    
        .shadow {
            --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
            --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px #0000001a, 0 1px 2px -1px #0000001a;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .shadow-inner {
            --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .05);
            --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, inset 0 2px 4px #0000000d;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .shadow-md {
            --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
            --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .shadow-sm {
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
            --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px #0000000d;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .outline-none {
            outline: 2px solid transparent;
            outline-offset: 2px
        }
    
        .outline {
            outline-style: solid
        }
    
        .ring-gray-200 {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(229 231 235/var(--tw-ring-opacity))
        }
    
        .ring-green-500 {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(34 197 94/var(--tw-ring-opacity))
        }
    
        .ring-lightBlue-500 {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(14 165 233/var(--tw-ring-opacity))
        }
    
        .ring-opacity-25 {
            --tw-ring-opacity: .25
        }
    
        .ring-opacity-30 {
            --tw-ring-opacity: .3
        }
    
        .ring-offset-0 {
            --tw-ring-offset-width: 0px
        }
    
        .blur {
            --tw-blur: blur(8px);
            filter: blur(8px) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
        }
    
        .blur, .filter {
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
        }
    
        .transition {
            transition-duration: .15s;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1)
        }
    
        .transition-all {
            transition-duration: .15s;
            transition-property: all;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1)
        }
    
        .duration-200 {
            transition-duration: .2s
        }
    
        .duration-300 {
            transition-duration: .3s
        }
    
        .duration-500 {
            transition-duration: .5s
        }
    
        .ease-out {
            transition-timing-function: cubic-bezier(0, 0, .2, 1)
        }
    
        .hover:border-black:hover {
            --tw-border-opacity: 1;
            border-color: #000;
            border-color: rgb(0 0 0/var(--tw-border-opacity))
        }
    
        .hover:border-gray-400:hover {
            --tw-border-opacity: 1;
            border-color: #9ca3af;
            border-color: rgb(156 163 175/var(--tw-border-opacity))
        }
    
        .hover:border-gray-500:hover {
            --tw-border-opacity: 1;
            border-color: #6b7280;
            border-color: rgb(107 114 128/var(--tw-border-opacity))
        }
    
        .hover:border-yellow-500:hover {
            --tw-border-opacity: 1;
            border-color: #eab308;
            border-color: rgb(234 179 8/var(--tw-border-opacity))
        }
    
        .hover:border-opacity-80:hover {
            --tw-border-opacity: .8
        }
    
        .hover:bg-black:hover {
            --tw-bg-opacity: 1;
            background-color: #000;
            background-color: rgb(0 0 0/var(--tw-bg-opacity))
        }
    
        .hover:bg-blue-600:hover {
            --tw-bg-opacity: 1;
            background-color: #2563eb;
            background-color: rgb(37 99 235/var(--tw-bg-opacity))
        }
    
        .hover:bg-gray-100:hover {
            --tw-bg-opacity: 1;
            background-color: #f3f4f6;
            background-color: rgb(243 244 246/var(--tw-bg-opacity))
        }
    
        .hover:bg-gray-200:hover {
            --tw-bg-opacity: 1;
            background-color: #e5e7eb;
            background-color: rgb(229 231 235/var(--tw-bg-opacity))
        }
    
        .hover:bg-gray-300:hover {
            --tw-bg-opacity: 1;
            background-color: #d1d5db;
            background-color: rgb(209 213 219/var(--tw-bg-opacity))
        }
    
        .hover:bg-gray-50:hover {
            --tw-bg-opacity: 1;
            background-color: #f9fafb;
            background-color: rgb(249 250 251/var(--tw-bg-opacity))
        }
    
        .hover:bg-lightBlue-700:hover {
            --tw-bg-opacity: 1;
            background-color: #0369a1;
            background-color: rgb(3 105 161/var(--tw-bg-opacity))
        }
    
        .hover:bg-lightBlue-800:hover {
            --tw-bg-opacity: 1;
            background-color: #075985;
            background-color: rgb(7 89 133/var(--tw-bg-opacity))
        }
    
        .hover:bg-yellow-500:hover {
            --tw-bg-opacity: 1;
            background-color: #eab308;
            background-color: rgb(234 179 8/var(--tw-bg-opacity))
        }
    
        .hover:bg-opacity-10:hover {
            --tw-bg-opacity: .1
        }
    
        .hover:bg-opacity-50:hover {
            --tw-bg-opacity: .5
        }
    
        .hover:bg-opacity-90:hover {
            --tw-bg-opacity: .9
        }
    
        .hover:text-black:hover {
            --tw-text-opacity: 1;
            color: #000;
            color: rgb(0 0 0/var(--tw-text-opacity))
        }
    
        .hover:underline:hover {
            text-decoration-line: underline
        }
    
        .focus:border-2:focus {
            border-width: 2px
        }
    
        .focus:border-[#096cc7]:focus {
            --tw-border-opacity: 1;
            border-color: #096cc7;
            border-color: rgb(9 108 199/var(--tw-border-opacity))
        }
    
        .focus:border-black:focus {
            --tw-border-opacity: 1;
            border-color: #000;
            border-color: rgb(0 0 0/var(--tw-border-opacity))
        }
    
        .focus:border-blue-400:focus {
            --tw-border-opacity: 1;
            border-color: #60a5fa;
            border-color: rgb(96 165 250/var(--tw-border-opacity))
        }
    
        .focus:border-blue-600:focus {
            --tw-border-opacity: 1;
            border-color: #2563eb;
            border-color: rgb(37 99 235/var(--tw-border-opacity))
        }
    
        .focus:border-blueGray-800:focus {
            --tw-border-opacity: 1;
            border-color: #1e293b;
            border-color: rgb(30 41 59/var(--tw-border-opacity))
        }
    
        .focus:border-cyan-600:focus {
            --tw-border-opacity: 1;
            border-color: #0891b2;
            border-color: rgb(8 145 178/var(--tw-border-opacity))
        }
    
        .focus:border-gray-400:focus {
            --tw-border-opacity: 1;
            border-color: #9ca3af;
            border-color: rgb(156 163 175/var(--tw-border-opacity))
        }
    
        .focus:border-green-600:focus {
            --tw-border-opacity: 1;
            border-color: #16a34a;
            border-color: rgb(22 163 74/var(--tw-border-opacity))
        }
    
        .focus:border-lightBlue-500:focus {
            --tw-border-opacity: 1;
            border-color: #0ea5e9;
            border-color: rgb(14 165 233/var(--tw-border-opacity))
        }
    
        .focus:border-lightBlue-700:focus {
            --tw-border-opacity: 1;
            border-color: #0369a1;
            border-color: rgb(3 105 161/var(--tw-border-opacity))
        }
    
        .focus:border-opacity-100:focus {
            --tw-border-opacity: 1
        }
    
        .focus:border-opacity-50:focus {
            --tw-border-opacity: .5
        }
    
        .focus:border-opacity-80:focus {
            --tw-border-opacity: .8
        }
    
        .focus:bg-black:focus {
            --tw-bg-opacity: 1;
            background-color: #000;
            background-color: rgb(0 0 0/var(--tw-bg-opacity))
        }
    
        .focus:bg-gray-200:focus {
            --tw-bg-opacity: 1;
            background-color: #e5e7eb;
            background-color: rgb(229 231 235/var(--tw-bg-opacity))
        }
    
        .focus:bg-lightBlue-700:focus {
            --tw-bg-opacity: 1;
            background-color: #0369a1;
            background-color: rgb(3 105 161/var(--tw-bg-opacity))
        }
    
        .focus:bg-lightBlue-800:focus {
            --tw-bg-opacity: 1;
            background-color: #075985;
            background-color: rgb(7 89 133/var(--tw-bg-opacity))
        }
    
        .focus:bg-opacity-10:focus {
            --tw-bg-opacity: .1
        }
    
        .focus:shadow-docusign-button-focus:focus {
            --tw-shadow: 0 0 3px 1px #4186ec, 0 0 0 1px #2c6ee1;
            --tw-shadow-colored: 0 0 3px 1px var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, 0 0 3px 1px #4186ec, 0 0 0 1px #2c6ee1;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .focus:shadow-docusign-input-focus:focus {
            --tw-shadow: 0 0 3px #4186ec;
            --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
            box-shadow: 0 0 #0000, 0 0 #0000, 0 0 3px #4186ec;
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
        }
    
        .focus:outline-none:focus {
            outline: 2px solid transparent;
            outline-offset: 2px
        }
    
        .focus:ring-1:focus {
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
        }
    
        .focus:ring-2:focus {
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
        }
    
        .focus:ring-4:focus {
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
        }
    
        .focus:ring-blue-600:focus {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(37 99 235/var(--tw-ring-opacity))
        }
    
        .focus:ring-indigo-500:focus {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(99 102 241/var(--tw-ring-opacity))
        }
    
        .focus:ring-lightBlue-500:focus {
            --tw-ring-opacity: 1;
            --tw-ring-color: rgb(14 165 233/var(--tw-ring-opacity))
        }
    
        .focus:ring-offset-2:focus {
            --tw-ring-offset-width: 2px
        }
    
        .disabled:bg-white:disabled {
            --tw-bg-opacity: 1;
            background-color: #fff;
            background-color: rgb(255 255 255/var(--tw-bg-opacity))
        }
    
        .group:hover .group-hover:border-blue-600 {
            --tw-border-opacity: 1;
            border-color: #2563eb;
            border-color: rgb(37 99 235/var(--tw-border-opacity))
        }
    
        @media (min-width: 640px) {
            .sm:mx-auto {
                margin-left: auto;
                margin-right: auto
            }
    
            .sm:mb-6 {
                margin-bottom: 1.5rem
            }
    
            .sm:block {
                display: block
            }
    
            .sm:flex {
                display: flex
            }
    
            .sm:hidden {
                display: none
            }
    
            .sm:h-auto {
                height: auto
            }
    
            .sm:min-h-0 {
                min-height: 0
            }
    
            .sm:w-96 {
                width: 24rem
            }
    
            .sm:w-full {
                width: 100%
            }
    
            .sm:min-w-0 {
                min-width: 0
            }
    
            .sm:max-w-400 {
                max-width: 400px
            }
    
            .sm:max-w-md {
                max-width: 28rem
            }
    
            .sm:max-w-sm {
                max-width: 24rem
            }
    
            .sm:flex-row {
                flex-direction: row
            }
    
            .sm:items-center {
                align-items: center
            }
    
            .sm:justify-start {
                justify-content: flex-start
            }
    
            .sm:space-x-4 > :not([hidden]) ~ :not([hidden]) {
                --tw-space-x-reverse: 0;
                margin-left: 1rem;
                margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
                margin-right: 0;
                margin-right: calc(1rem * var(--tw-space-x-reverse))
            }
    
            .sm:space-y-0 > :not([hidden]) ~ :not([hidden]) {
                --tw-space-y-reverse: 0;
                margin-bottom: 0;
                margin-bottom: calc(0px * var(--tw-space-y-reverse));
                margin-top: 0;
                margin-top: calc(0px * (1 - var(--tw-space-y-reverse)))
            }
    
            .sm:border {
                border-width: 1px
            }
    
            .sm:bg-gray-100 {
                --tw-bg-opacity: 1;
                background-color: #f3f4f6;
                background-color: rgb(243 244 246/var(--tw-bg-opacity))
            }
    
            .sm:bg-quickbooks {
                --tw-bg-opacity: 1;
                background-color: #193048;
                background-color: rgb(25 48 72/var(--tw-bg-opacity))
            }
    
            .sm:bg-warmGray-50 {
                --tw-bg-opacity: 1;
                background-color: #fafaf9;
                background-color: rgb(250 250 249/var(--tw-bg-opacity))
            }
    
            .sm:bg-white {
                --tw-bg-opacity: 1;
                background-color: #fff;
                background-color: rgb(255 255 255/var(--tw-bg-opacity))
            }
    
            .sm:p-0 {
                padding: 0
            }
    
            .sm:p-11 {
                padding: 2.75rem
            }
    
            .sm:px-0 {
                padding-left: 0;
                padding-right: 0
            }
    
            .sm:px-1 {
                padding-left: .25rem;
                padding-right: .25rem
            }
    
            .sm:px-1.5 {
                padding-left: .375rem;
                padding-right: .375rem
            }
    
            .sm:px-10 {
                padding-left: 2.5rem;
                padding-right: 2.5rem
            }
    
            .sm:px-12 {
                padding-left: 3rem;
                padding-right: 3rem
            }
    
            .sm:px-6 {
                padding-left: 1.5rem;
                padding-right: 1.5rem
            }
    
            .sm:py-12 {
                padding-bottom: 3rem;
                padding-top: 3rem
            }
    
            .sm:pl-36 {
                padding-left: 9rem
            }
    
            .sm:pt-14 {
                padding-top: 3.5rem
            }
    
            .sm:pt-16 {
                padding-top: 4rem
            }
    
            .sm:pt-24 {
                padding-top: 6rem
            }
    
            .sm:text-base {
                font-size: 1rem;
                line-height: 1.5rem
            }
    
            .sm:text-sm {
                font-size: .875rem;
                line-height: 1.25rem
            }
    
            .sm:shadow-lg {
                --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
                --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
                box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
            }
    
            .sm:shadow-sm {
                --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
                --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
                box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px #0000000d;
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
            }
    
            .sm:ring-6 {
                --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
                --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(6px + var(--tw-ring-offset-width)) var(--tw-ring-color);
                box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(6px + var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
                box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
            }
        }
    
        @media (min-width: 768px) {
            .md:mx-auto {
                margin-left: auto;
                margin-right: auto
            }
    
            .md:mb-10 {
                margin-bottom: 2.5rem
            }
    
            .md:mt-32 {
                margin-top: 8rem
            }
    
            .md:block {
                display: block
            }
    
            .md:flex {
                display: flex
            }
    
            .md:h-20 {
                height: 5rem
            }
    
            .md:h-screen {
                height: 100vh
            }
    
            .md:w-11/12 {
                width: 91.666667%
            }
    
            .md:max-w-460 {
                max-width: 460px
            }
    
            .md:max-w-md {
                max-width: 28rem
            }
    
            .md:flex-row {
                flex-direction: row
            }
    
            .md:flex-col {
                flex-direction: column
            }
    
            .md:justify-start {
                justify-content: flex-start
            }
    
            .md:justify-center {
                justify-content: center
            }
    
            .md:space-y-0 > :not([hidden]) ~ :not([hidden]) {
                --tw-space-y-reverse: 0;
                margin-bottom: 0;
                margin-bottom: calc(0px * var(--tw-space-y-reverse));
                margin-top: 0;
                margin-top: calc(0px * (1 - var(--tw-space-y-reverse)))
            }
    
            .md:rounded-lg {
                border-radius: .5rem
            }
    
            .md:border {
                border-width: 1px
            }
    
            .md:p-6 {
                padding: 1.5rem
            }
    
            .md:px-8 {
                padding-left: 2rem;
                padding-right: 2rem
            }
    
            .md:pb-8 {
                padding-bottom: 2rem
            }
    
            .md:pt-6 {
                padding-top: 1.5rem
            }
    
            .md:shadow-atlassian {
                --tw-shadow: rgba(0, 0, 0, .1) 0px 0px 10px;
                --tw-shadow-colored: 0px 0px 10px var(--tw-shadow-color);
                box-shadow: 0 0 #0000, 0 0 #0000, 0 0 10px #0000001a;
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
            }
    
            .md:shadow-lg {
                --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
                --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
                box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
            }
        }
    
        @media (min-width: 1024px) {
            .lg:mb-32 {
                margin-bottom: 8rem
            }
    
            .lg:mt-8 {
                margin-top: 2rem
            }
    
            .lg:block {
                display: block
            }
    
            .lg:flex {
                display: flex
            }
    
            .lg:h-20 {
                height: 5rem
            }
    
            .lg:h-auto {
                height: auto
            }
    
            .lg:w-1/2 {
                width: 50%
            }
    
            .lg:w-[71%] {
                width: 71%
            }
    
            .lg:w-full {
                width: 100%
            }
    
            .lg:max-w-[414px] {
                max-width: 414px
            }
    
            .lg:max-w-none {
                max-width: none
            }
    
            .lg:max-w-sm {
                max-width: 24rem
            }
    
            .lg:flex-row {
                flex-direction: row
            }
    
            .lg:items-center {
                align-items: center
            }
    
            .lg:justify-normal {
                justify-content: normal
            }
    
            .lg:justify-between {
                justify-content: space-between
            }
    
            .lg:px-0 {
                padding-left: 0;
                padding-right: 0
            }
    
            .lg:px-8 {
                padding-left: 2rem;
                padding-right: 2rem
            }
    
            .lg:text-2xl {
                font-size: 1.5rem;
                line-height: 2rem
            }
    
            .lg:leading-7 {
                line-height: 1.75rem
            }
        }
    
        @media (min-width: 1280px) {
            .xl:h-[110px] {
                height: 110px
            }
    
            .xl:max-w-lg {
                max-width: 32rem
            }
    
            .xl:text-4xl {
                font-size: 2.25rem;
                line-height: 2.5rem
            }
        }
    
`