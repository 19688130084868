import i18next from 'i18next'
import en from './translations/en.json'
import pl from './translations/pl.json'
import { initReactI18next } from 'react-i18next'



const resources = {
  en: { translation: en },
  pl: { translation: pl },
}

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang'),
  fallbackLng: 'en',

})

export default i18next;