import i18next from '../../../i18n';

export function timeLineSteps() {
  return [
    {
      id: 1,
      title: i18next.t('Beginner'),
      type: 'start',
      description: i18next.t('descriptionLvl1'),
      score: 0 * 2,
      icon: '',
      conmpletedIcon: '',
      progressGradient: 'linear-gradient(90deg, #AD31F8 0%, #7910FF 100%)',
    },
    {
      id: 2,
      title: i18next.t('Newbie'),
      type: 'step',
      description: i18next.t('descriptionLvl2'),
      score: 3 * 2,
      icon: 'achivement1',
      conmpletedIcon: 'completed-milestone-icon.png',
      progressGradient: 'linear-gradient(90deg, #AD31F8 0%, #7910FF 100%)',
    },
    {
      id: 3,
      title: i18next.t('Rising Star'),
      type: 'step',
      description: i18next.t('descriptionLvl3'),
      score: 6 * 2,
      icon: 'achivement2',
      conmpletedIcon: 'completed-milestone-icon.png',
      progressGradient: 'linear-gradient(90deg, #AD31F8 0%, #7910FF 100%)',
    },
    {
      id: 4,
      title: i18next.t('Squire'),
      type: 'step',
      description: i18next.t('descriptionLvl4'),
      score: 10 * 2,
      icon: 'achivement3',
      conmpletedIcon: 'completed-milestone-icon.png',
      progressGradient: 'linear-gradient(90deg, #AD31F8 0%, #7910FF 100%)',
    },
    {
      id: 5,
      title: i18next.t('Basic'),
      type: 'level',
      description: i18next.t('descriptionLvl5'),
      score: 20 * 2,
      icon: 'level-icon-1',
      conmpletedIcon: 'completed-milestone-icon.png',
      progressGradient: 'linear-gradient(90deg, #790FFF 0%, #17C0F9 100%)',
    },
    {
      id: 6,
      title: i18next.t('Sentinel'),
      type: 'step',
      description: i18next.t('descriptionLvl6'),
      score: 26 * 2,
      icon: 'achivement4',
      conmpletedIcon: 'completed-milestone-icon-blue.png',
      progressGradient: 'linear-gradient(90deg, #790FFF 0%, #17C0F9 100%)',
    },
    {
      id: 7,
      title: i18next.t('Defender'),
      type: 'step',
      description: i18next.t('descriptionLvl7'),
      score: 30 * 2,
      icon: 'achivement5',
      conmpletedIcon: 'completed-milestone-icon-blue.png',
      progressGradient: 'linear-gradient(90deg, #790FFF 0%, #17C0F9 100%)',
    },
    {
      id: 17,
      title: i18next.t('Guardian'),
      type: 'step',
      description: i18next.t('descriptionLvl17'),
      score: 40 * 2,
      icon: 'achivement12',
      conmpletedIcon: 'completed-milestone-icon-blue.png',
      progressGradient: 'linear-gradient(90deg, #790FFF 0%, #17C0F9 100%)',
    },
    {
      id: 8,
      title: i18next.t('Intermediary'),
      type: 'level',
      description: i18next.t('descriptionLvl8'),
      score: 46 * 2,
      icon: 'level-icon-2',
      conmpletedIcon: 'completed-milestone-icon-blue.png',
      progressGradient: 'linear-gradient(90deg, #7811FF 0%, #19C773 100%)',
    },
    {
      id: 9,
      title: i18next.t('Warden'),
      type: 'step',
      description: i18next.t('descriptionLvl9'),
      score: 50 * 2,
      icon: 'achivement6',
      conmpletedIcon: 'completed-milestone-icon-green.png',
      progressGradient: 'linear-gradient(90deg, #7811FF 0%, #19C773 100%)',
    },
    {
      id: 10,
      title: i18next.t('Firewall'),
      type: 'step',
      description: i18next.t('descriptionLvl10'),
      score: 54 * 2,
      icon: 'achivement7',
      conmpletedIcon: 'completed-milestone-icon-green.png',
      progressGradient: 'linear-gradient(90deg, #7811FF 0%, #19C773 100%)',
    },
    {
      id: 11,
      title: i18next.t('Vanguard'),
      type: 'step',
      description: i18next.t('descriptionLvl11'),
      score: 60 * 2,
      icon: 'achivement8',
      conmpletedIcon: 'completed-milestone-icon-green.png',
      progressGradient: 'linear-gradient(90deg, #7811FF 0%, #19C773 100%)',
    },
    {
      id: 12,
      title: i18next.t('Master'),
      type: 'level',
      description: i18next.t('descriptionLvl12'),
      score: 70 * 2,
      icon: 'level-icon-3',
      conmpletedIcon: 'completed-milestone-icon-green.png',
      progressGradient: 'linear-gradient(90deg, #A01BFE 0%, #8312FF 100%)',
    },
    {
      id: 13,
      title: i18next.t('Ironclad'),
      type: 'step',
      description: i18next.t('descriptionLvl13'),
      score: 74 * 2,
      icon: 'achivement9',
      conmpletedIcon: 'completed-milestone-icon-violet.png',
      progressGradient: 'linear-gradient(90deg, #A01BFE 0%, #8312FF 100%)',
    },
    {
      id: 14,
      title: i18next.t('Paladin'),
      type: 'step',
      description: i18next.t('descriptionLvl14'),
      score: 80 * 2,
      icon: 'achivement10',
      conmpletedIcon: 'completed-milestone-icon-violet.png',
      progressGradient: 'linear-gradient(90deg, #A01BFE 0%, #8312FF 100%)',
    },
    {
      id: 15,
      title: i18next.t('Gatekeeper'),
      type: 'step',
      description: i18next.t('descriptionLvl15'),
      score: 90 * 2,
      icon: 'achivement11',
      conmpletedIcon: 'completed-milestone-icon-violet.png',
      progressGradient: 'linear-gradient(90deg, #A01BFE 0%, #8312FF 100%)',
    },
    {
      id: 16,
      title: i18next.t('Grand Master'),
      type: 'level',
      description: i18next.t('descriptionLvl16'),
      score: 100 * 2,
      icon: 'level-icon-4',
      conmpletedIcon: 'completed-milestone-icon-violet.png',
      progressGradient: 'linear-gradient(90deg, #A01BFE 0%, #8312FF 100%)',
    },
  ]
}

