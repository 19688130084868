import React, { useEffect, useRef } from "react"
import { timeLineSteps } from "../constants"

const SkeletonMilestoneText = () => (
  <svg width="204" height="72" viewBox="0 0 204 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <rect width="145" height="24" rx="2" fill="url(#paint0_linear_1346_9919)" />
      <rect y="36" width="204" height="16" rx="2" fill="url(#paint1_linear_1346_9919)" />
      <rect y="56" width="145" height="16" rx="2" fill="url(#paint2_linear_1346_9919)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1346_9919" x1="-4.72646e-07" y1="12" x2="145" y2="12" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient id="paint1_linear_1346_9919" x1="-6.64964e-07" y1="44" x2="204" y2="44" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient id="paint2_linear_1346_9919" x1="-4.72646e-07" y1="64" x2="145" y2="64" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </svg>
)

const StartIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" transform="matrix(-1 0 0 1 60 0)" fill="#00BF41" />
    <path d="M47.3978 29.9571L20.0802 45.3556L27.0154 30.4328L19.3917 15.8496L47.3978 29.9571Z" fill="white" />
  </svg>
)

const StarIcon25 = () => (
  <svg style={{ margin: '-17px -15px -15px 0px' }} width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_762_61452)">
      <path d="M27.8596 18.222C28.2396 17.2987 29.1036 17.0226 29.4881 17C30.248 17 30.7547 17.5884 30.913 17.8826L33.7628 24.2643C36.1376 24.4907 40.9144 24.9568 41.023 25.0112C41.1587 25.079 41.8372 25.3506 41.9729 26.1653C42.0815 26.8171 41.8372 27.2968 41.7015 27.4552L36.3412 32.4113C36.7709 34.1765 37.6711 37.9376 37.8339 38.8609C37.8339 39.2004 37.9018 39.9472 37.2911 40.5582C36.8026 41.047 36.0924 41.0335 35.7984 40.9656L29.4202 37.2994C27.5656 38.4083 23.7885 40.6533 23.5171 40.7619C23.1778 40.8977 22.4993 41.1692 21.685 40.6261C21.0337 40.2459 21.097 39.2457 21.2101 38.793L22.635 32.3434C20.9839 30.8724 17.6275 27.8762 17.4103 27.6589C17.1389 27.3873 16.8675 26.9121 17.0711 25.9616C17.2339 25.2012 18.0889 24.9659 18.496 24.9433L25.2134 24.2643C25.9371 22.635 27.4796 19.1454 27.8596 18.222Z" fill="url(#paint0_radial_762_61452)" />
    </g>
    <path d="M29.4881 16.7284H29.4801L29.4721 16.7289C29.019 16.7556 28.0401 17.0699 27.6085 18.1187L27.8596 18.222L27.6085 18.1187C27.2411 19.0114 25.7769 22.3258 25.0292 24.01L18.4745 24.6725C18.238 24.6866 17.8849 24.7597 17.5611 24.9378C17.2304 25.1197 16.9083 25.4248 16.8055 25.9048C16.6966 26.4133 16.7104 26.8156 16.8012 27.1383C16.8925 27.4632 17.0566 27.6891 17.2183 27.8509C17.4375 28.0702 20.6532 30.9412 22.3355 32.4402L20.9457 38.731C20.883 38.9838 20.8348 39.3813 20.8921 39.7758C20.9491 40.1679 21.1193 40.6064 21.5409 40.8564C22.4712 41.473 23.2635 41.1559 23.6168 41.0145L23.618 41.014C23.6719 40.9924 23.7788 40.9347 23.9046 40.8647C24.0393 40.7897 24.2178 40.688 24.4288 40.5662C24.8512 40.3225 25.4076 39.9965 26.0129 39.6397C27.1655 38.9601 28.4974 38.1673 29.4229 37.6142L35.663 41.201L35.698 41.2211L35.7373 41.2302C36.0775 41.3087 36.9013 41.3324 37.4832 40.7502C38.1282 40.1048 38.1136 39.3187 38.1067 38.9502C38.1061 38.9169 38.1055 38.887 38.1055 38.8609V38.8372L38.1014 38.8138C37.9413 37.906 37.0843 34.3193 36.6428 32.5023L41.8859 27.6546L41.8975 27.6439L41.9078 27.6319C42.0926 27.4161 42.3634 26.8563 42.2408 26.1207C42.0894 25.2116 41.3604 24.8697 41.1736 24.7822C41.1616 24.7765 41.1517 24.7719 41.1445 24.7683C41.1161 24.7541 41.0901 24.7474 41.0841 24.7458C41.0729 24.7428 41.062 24.7405 41.0531 24.7387C41.035 24.735 41.0138 24.7314 40.9914 24.728C40.946 24.7209 40.885 24.7126 40.8113 24.7033C40.6634 24.6847 40.4569 24.6609 40.2064 24.6334C39.705 24.5784 39.0225 24.5079 38.27 24.432C36.8299 24.2869 35.1306 24.1221 33.9462 24.009L31.1609 17.7719L31.1569 17.7627L31.1521 17.7539C31.0555 17.5743 30.8643 17.3233 30.5907 17.1149C30.3144 16.9045 29.9414 16.7284 29.4881 16.7284Z" fill="url(#paint1_radial_762_61452)" stroke="#FFE600" strokeWidth="0.543129" />
    <mask id="mask0_762_61452" maskUnits="userSpaceOnUse" x="17" y="17" width="25" height="24">
      <path d="M27.8596 18.222C28.2396 17.2987 29.1036 17.0226 29.4881 17C30.248 17 30.7547 17.5884 30.913 17.8826L33.7628 24.2643C36.1376 24.4907 40.9144 24.9568 41.023 25.0112C41.1587 25.079 41.8372 25.3506 41.9729 26.1653C42.0815 26.8171 41.8372 27.2968 41.7015 27.4552L36.3412 32.4113C36.7709 34.1765 37.6711 37.9376 37.8339 38.8609C37.8339 39.2004 37.9018 39.9472 37.2911 40.5582C36.8026 41.047 36.0924 41.0335 35.7984 40.9656L29.4202 37.2994C27.5656 38.4083 23.7885 40.6533 23.5171 40.7619C23.1778 40.8977 22.4993 41.1692 21.685 40.6261C21.0337 40.2459 21.097 39.2457 21.2101 38.793L22.635 32.3434C20.9839 30.8724 17.6275 27.8762 17.4103 27.6589C17.1389 27.3873 16.8675 26.9121 17.0711 25.9616C17.2339 25.2012 18.0889 24.9659 18.496 24.9433L25.2134 24.2643C25.9371 22.635 27.4796 19.1454 27.8596 18.222Z" fill="url(#paint2_radial_762_61452)" />
    </mask>
    <g mask="url(#mask0_762_61452)">
      <ellipse cx="25.0097" cy="22.9763" rx="9.09224" ry="9.09741" fill="url(#paint3_radial_762_61452)" />
    </g>
    <defs>
      <filter id="filter0_d_762_61452" x="0.199999" y="0.199999" width="58.6" height="57.6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="8.4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.92 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_61452" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_61452" result="shape" />
      </filter>
      <radialGradient id="paint0_radial_762_61452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.824 22.0918) rotate(59.1578) scale(18.6613 19.4447)">
        <stop stopColor="#EBFF00" />
        <stop offset="1" stopColor="#FFE600" />
      </radialGradient>
      <radialGradient id="paint1_radial_762_61452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.824 22.0918) rotate(59.1578) scale(18.6613 19.4447)">
        <stop stopColor="#EBFF00" />
        <stop offset="1" stopColor="#FFE600" />
      </radialGradient>
      <radialGradient id="paint2_radial_762_61452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.824 22.0918) rotate(59.1578) scale(18.6613 19.4447)">
        <stop stopColor="#EBFF00" />
        <stop offset="1" stopColor="#FFE600" />
      </radialGradient>
      <radialGradient id="paint3_radial_762_61452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.0251 20.8982) rotate(55.953) scale(15.1424 15.1391)">
        <stop offset="0.140625" stopColor="#EBFF00" />
        <stop offset="1" stopColor="#FFFBF5" />
      </radialGradient>
    </defs>
  </svg>

)

const Indicator = ({ style }) => {
  return (
    <svg className='timeline-progress-indicator' style={style} width="34" height="52" viewBox="0 0 34 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 19.6L25 18V34L4 32.4V29.2L0 26L4 23V19.6Z" fill="white" />
      <g filter="url(#filter0_d_762_61458)">
        <path d="M12.3726 6L27.6667 16V34.3333L12.3726 46V6Z" fill="#FFE229" />
      </g>
      <g filter="url(#filter1_d_762_61458)">
        <path d="M6 19.5L13.6471 19V26L6 22.25V19.5Z" fill="#FFE229" />
      </g>
      <g filter="url(#filter2_d_762_61458)">
        <path d="M6 30.082L13.6471 26.332V33.332L6 32.5V30.082Z" fill="#FFE229" />
      </g>
      <defs>
        <filter id="filter0_d_762_61458" x="6.37256" y="0" width="27.2942" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_61458" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_61458" result="shape" />
        </filter>
        <filter id="filter1_d_762_61458" x="0" y="13" width="19.647" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_61458" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_61458" result="shape" />
        </filter>
        <filter id="filter2_d_762_61458" x="0" y="20.332" width="19.647" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_762_61458" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_762_61458" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

const TimeLine = ({ score }) => {
  const myRef = useRef(null)

  useEffect(() => {
    if (score) {
      myRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'end',
        inline: 'center'
      })
    }
  }, [score])

  return (
    <div style={{ alignItems: 'flex-start' }} className='row time-line'>
      {timeLineSteps().map((el, index) => (
        <div key={el.id} className='timeline-step'>
          <div ref={(score >= 100 && index == timeLineSteps()?.length - 1) ? myRef : (score > el.score && score < timeLineSteps()[index + 1]?.score) ? myRef : null} style={{ marginTop: '180px', position: 'relative' }} className='row row-center'>
            {el.type == 'start' ? <StartIcon /> :
              score < el.score ? (
                <img src="/images/milestone-loked.png" width={'173px'} style={{ margin: '-70px -50px 0px -45px' }} alt="" />
              ) : (
                <img src="/images/completed-milestone-icon.png" alt="" width={'173px'} style={{ margin: '-58px -50px -124px -54px' }} />
              )

            }
            {el.type == 'level' ? (
              <div className='level-label-wrapper'>
                <div className={`level-label row row-center ${score < el.score && 'level-label-locked'}`}>
                  <svg style={{ width: '60px' }} width="99" height="33" viewBox="0 0 99 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.4806 2.33163C25.0921 1.04358 26.3904 0.222656 27.8162 0.222656H94.7845C97.017 0.222656 98.7388 2.18844 98.4447 4.40143L95.173 29.0168C94.9291 30.8519 93.3641 32.2227 91.5129 32.2227H16.1318C13.42 32.2227 11.6333 29.3969 12.7962 26.947L24.4806 2.33163Z" fill="#FCC536" />
                    <path d="M15.6363 2.96641C16.0663 1.3487 17.5308 0.222656 19.2047 0.222656H87.4112C89.6713 0.222656 91.4011 2.23496 91.0617 4.46945L87.3226 29.0848C87.0486 30.8892 85.4973 32.2227 83.6722 32.2227H12.6614C10.2367 32.2227 8.47009 29.9252 9.09301 27.5818L15.6363 2.96641Z" fill="#FE912B" />
                    <path d="M4.38409 3.36047C4.65817 1.55609 6.20944 0.222656 8.03453 0.222656H78.9063C81.1665 0.222656 82.8962 2.23496 82.5568 4.46945L78.8177 29.0848C78.5437 30.8892 76.9924 32.2227 75.1673 32.2227H4.29551C2.03539 32.2227 0.305664 30.2104 0.645078 27.9759L4.38409 3.36047Z" fill="#FC6A00" />
                    <path d="M14.28 8.92266V20.7227H20.12V23.7227H11.04V8.92266H14.28ZM32.0533 23.7227H22.6533V8.92266H32.0533V11.9227H25.8933V14.8227H31.3533V17.6627H25.8933V20.7227H32.0533V23.7227ZM39.4041 23.7227L33.8641 8.92266H37.3241L40.1241 16.4027C40.4574 17.296 40.7707 18.336 41.0641 19.5227C41.3574 18.456 41.6841 17.416 42.0441 16.4027L44.8041 8.92266H48.1841L42.7441 23.7227H39.4041ZM59.8306 23.7227H50.4306V8.92266H59.8306V11.9227H53.6706V14.8227H59.1306V17.6627H53.6706V20.7227H59.8306V23.7227ZM66.2214 8.92266V20.7227H72.0614V23.7227H62.9814V8.92266H66.2214Z" fill="white" />
                  </svg>

                  <p>{el.title}</p>
                  {score >= el.score &&
                    <img src={'/images/' + el.icon + '.png'} alt="" />
                  }

                </div>

              </div>
            ) : (
              el.icon &&
              <img className={`achivement-image ${score < el.score ? 'achivement-image-locked ' : ' '}${(score >= el.score && score < timeLineSteps()[index + 1]?.score) ? 'flip' : ''}`} src={'/images/' + el.icon + '.png'} alt={el.title + ' image'} />
            )}

            {timeLineSteps().length - 1 !== index && (

              <div className='timeline-progress-bar'>
                {score >= el.score &&
                  <div style={{ width: score >= timeLineSteps()[index + 1]?.score ? '100%' : '50%', background: el.progressGradient }} className='timeline-progress-content'></div>
                }
                {score > el.score
                  && score < timeLineSteps()[index + 1]?.score
                  &&
                  <>
                    <Indicator style={{ left: 'calc(50% - 20px)', top: '-21px' }} />
                    <div style={{ left: 'calc(50% - 50px)', top: '46px' }}
                      className='current-score row row-center'>
                      <p>{score}</p>
                      <StarIcon25 />
                    </div>
                  </>
                }
              </div>
            )}
          </div>

          <div style={{ position: 'relative', marginTop: '22px', marginBottom: '32px' }} className='row row-center milestone-score-container'>
            <div ref={score == el.score ? myRef : null} className='milestone-score'>
              <p>{el.score}</p>
            </div>
          </div>
          {
            score < el.score ? (
              <SkeletonMilestoneText />
            ) : (
              <>
                {el.type !== 'level' && <h3 className='milestone-title' style={{ maxWidth: '300px' }}>{el.title}</h3>}
                <p className='milestone-description' style={{ maxWidth: '300px' }}>{el.description}</p>
              </>
            )
          }

        </div>
      ))}



    </div>
  )
}

export default TimeLine