import React, { useRef, useState } from 'react'

import { Box, Button, Typography, styled, } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import cardsService from 'src/services/cards.service'

import './styles.css'
import { useQuery } from 'src/hooks'
import { Letter } from 'react-letter'
import Spacer from 'src/common/components/Spacer'
import { useTranslation } from 'react-i18next'
import i18next from '../../i18n'

const Card = styled(Box)({
  borderRadius: '30px',
  width: '1000px',
  // position: 'absolute',
  transition: 'margin-left .4s ease-out,opacity .2s ease-out,transform .4s ease-out .1s',
  transformOrigin: 'center right',
  boxShadow: 'inset 0 0 0 1px #27274714',
  overflow: 'hidden',

  '@media (max-width: 1000px)': {
    width: 'calc(100% - 40px)',

    '& > div > img': {
      width: '1000px',
    }
  },

  '@media (max-width: 480px)': {
    width: 'calc(100% - 40px)',

    '& > div > img': {
      width: '480px',
    }
  }
})

const CardMedia = styled(Box)({

})

const CardTitle = styled(Typography)(() => ({
  color: '#303051',
  fontSize: '30px',
  fontWeight: '500',
  fontFamily: 'Satoshi',
  lineHeight: '43px',
  letterSpacing: '0em',
  textAlign: 'center',
  padding: '30px 20px 48px 20px',

  '& .imgBreaker': {
    display: 'none',
  },

  '@media (max-width: 480px)': {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '600',
    padding: '30px 20px 24px 20px',

    '& .imgBreaker': {
      display: 'block',
    },

  },
}))

const CardContent = styled(Box)(() => ({
  padding: '0px 20px',
  '& p': {
    color: '#303051',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '25px',
    letterSpacing: '0em',
    textAlign: 'center',
    padding: '0px 50px 50px 50px',

  }
}))

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );
    current && observer?.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  return isVisible;
};

const TrainingCardsV2 = () => {

  const [welcome, setWelcome] = useState(true);
  const [cards, setCards] = useState([]);
  const [activeCardNumber, setActiveCardNumber] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 560);
  const [template, setTemplate] = useState(null);
  const [isManual, setIsManual] = useState(null);

  const pixel = useRef(null)

  const isVisible = useIntersection(pixel, "0px");

  useEffect(() => {
    if (isVisible) {
      // console.log('is VISIBLE');
      if (query.get('id') !== 'manual-template') {
        if (sessionStorage.getItem('followLink') !== id) {
          if (hasQr) {
            cardsService.followQrLink(id).then(() => {
              sessionStorage.setItem('followLink', id)
            })
          } else {
            cardsService.followLink(id).then(() => {
              sessionStorage.setItem('followLink', id)
            })
          }
        }
      }
    }
  }, [isVisible]);

  const { t } = useTranslation()

  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 560)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)
  }, [])

  useEffect(() => {
    document.body.style.position = welcome ? 'fixed' : 'relative'
    document.body.style.width = '100vw '
  }, [welcome])

  let query = useQuery();
  const navigate = useNavigate();
  const id = query.get('id').split(',')[1];
  const hasQr = query.get('qrCode') == 'true'

  useEffect(() => {
    const lang = query.get('language')
      ? query.get('language') == 'Polish' ? 'pl' : 'en'
      : 'en'
    i18next.changeLanguage(lang)
    localStorage.setItem('lang', lang)

    if (query.get('id') == 'manual-template') {
      setIsManual(true)
    } else {

      cardsService.getCards(id).then(resp => {
        setCards([
          {
            line: t('Subject Line'),
            topic: resp.data.subjectLine,
            top: isMobile ? '70px' : '60px',
            left: isMobile ? '10px' : '98px',
            arrow: true,
          },
          {
            line: t('Sender Name'),
            topic: resp.data.senderName,
            top: isMobile ? '120px' : '110px',
            left: isMobile ? '10px' : '10px',
            arrow: true,
          },
          {
            line: t('Sender Email'),
            topic: resp.data.senderEmail,
            top: isMobile ? '130px' : '110px',
            left: isMobile ? '10px' : '177px',
            arrow: true,
          },
          {
            line: t('Email Body'),
            topic: resp.data.emailBody,
            top: '40%',
            left: isMobile ? '10px' : '50%',
            arrow: false,
          },
          {
            line: t('Link'),
            topic: resp.data.link,
            top: '40%',
            left: isMobile ? '10px' : '50%',
            arrow: false,
          },
          {
            line: t('Cyberlift button'),
            topic: t('Always use Cyberlift button in your inbox to report any suspicious emails.'),
            top: isMobile ? '150px' : '130px',
            left: isMobile ? '10px' : '700px',
            arrow: true,
            arrowPosition: isMobile ? 'calc(100% - 43px)' : '200px'
          },
        ]);
        setActiveCardNumber(0);
      }).catch(err => {
        console.error(err)
      })
      cardsService.getCardsTemplate(id).then(resp => {
        setTemplate(resp.data)
      })
    }

  }, [isMobile])

  const handleNext = () => {
    if (activeCardNumber < cards.length - 1) {
      setActiveCardNumber(activeCardNumber + 1)
    } else {
      cardsService.finishTraining(id).then(() => {
        navigate('/')
      })
    }
  }
  const handleBack = () => {
    if (activeCardNumber) {
      setActiveCardNumber(activeCardNumber - 1)
    }
  }


  return (<>
    <Box sx={{ background: '#E9E9FF', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '100dvh', overflow: 'auto' }}>
      {welcome && (
        <div style={{ zIndex: '10', position: 'fixed', height: '100dvh', width: '100%', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
          <Card sx={{ background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardMedia>
              <img width={'100%'} src={`/images/card1media.jpg`} alt="media" />
            </CardMedia>
            {/* <p style={{
              textAlign: 'center',
              color: '#CECEFF',
              fontSize: '24px',
              marginTop: '50px'
            }}>
              Bad news
            </p> */}
            <CardTitle>
              {t('You’re lucky!')}
              <br />
              {t('This could be a phishing email - but this time it was a training.')}
              <br className='imgBreaker' />
              <img width={'40px'} src={`/images/ok40.png`} alt="media" />
            </CardTitle>
            <CardContent>
              <p>
                {t('This email is part of cybersecurity phishing training.')}
                {t('Now let’s learn how to prepare yourself to identify and prevent these kinds of attacks.')}
                <br />
                {t('How to recognize phishing emails')}:

              </p>
            </CardContent>
            <Button onClick={() => {
              isManual ? navigate('/login') : setWelcome(false)
            }} style={{ backgroundColor: '#6123FF', }} sx={{ textTransform: 'none', fontSize: '20px', padding: '10px 30px', backgroundColor: '#6123FF', color: '#fff', marginBottom: '30px' }}>
              {t('Start explanation')}
              <svg style={{ marginLeft: 10 }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
              </svg>

            </Button>
          </Card>
        </div>

      )}

      <div style={{ height: 'calc(100vh - 60px)', width: '100%', position: 'relative', maxWidth: '1100px' }} className='row mail-container'>
        <div style={{ overflowY: 'auto', background: '#ffffff', padding: '0px', display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className='template-preview-box'>
            <p className='template-preview-subject'>{template?.subject}</p>
          </div>
          <div className='column template-preview-box' >
            <div className='row row-center template-actions'>
              <div className='sender-avatar'></div>
              <div className='column'>
                <div className='sender-and-email'>{template?.sender} <br /> {'<'}{template?.senderEmail}{'>'}</div>
                <div>To: You</div>
              </div>
              <Spacer />
              <img width={'20px'} src="https://outlook-plugin.cyberlift.io/assets/icon-64.png"></img>
              <img className='mob-hide' height={'20px'} src="/images/outlook-buttons.png"></img>
            </div>
            <div className="template-preview-wrapper">
              <Letter className='template-wrapper' style={{}} html={template?.body.replaceAll(' src="cid:[qrCodeImage]"', 'width="60px" src="https://admin.cyberlift.io/images/qr-in-template.jpg"')} />

            </div>
          </div>
        </div>

        <div
          className='column'
          style={{
            transition: '.2s',
            position: 'absolute',
            background: isMobile ? '#ffffffe0' : '#ffffffe0',
            border: '1px solid #6D4AFF55',
            padding: '40px',
            width: isMobile ? 'calc(100% - 20px)' : '400px',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            top: cards[activeCardNumber] ? cards[activeCardNumber]?.top : 0,
            left: cards && cards[activeCardNumber] ? cards[activeCardNumber]?.left : 0,
          }}
        >
          {cards[activeCardNumber]?.arrow && <div style={{ left: cards[activeCardNumber]?.arrowPosition }} className='tip-block-arrow'></div>}
          <h2 className='line'>{cards && cards[activeCardNumber]?.line}</h2>
          <p className='title'>{cards && cards[activeCardNumber]?.topic}</p>

          <div style={{ marginTop: '20px' }} className='row row-center'>
            <div style={{
              backgroundColor: '#6D4AFF',
              color: '#ffffff',
              width: '26px',
              height: '26px',
              borderRadius: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>{activeCardNumber + 1}</div>
            <Spacer />
            {activeCardNumber > 0 &&
              <Button sx={{ textTransform: 'none', marginRight: '16px', color: '#6D4AFF', borderColor: '#6D4AFF' }} onClick={handleBack} className='button' variant={'outlined'}>{t('Back')}</Button>
            }
            <Button style={{ background: '#6D4AFF' }} sx={{ textTransform: 'none' }} onClick={handleNext} className='button' variant={'contained'}>{activeCardNumber < cards?.length - 1 ? t('next') : t('End training')}</Button>
          </div>
        </div>

      </div>
      <div ref={pixel}></div>
    </Box >
  </>

  )
}

export default TrainingCardsV2
