import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.css'
import { Button, IconButton, Skeleton } from '@mui/material'
import Spacer from '../../common/components/Spacer';
import { useQuery } from 'src/hooks';
import { useEffect } from 'react';
import trainingsService from 'src/services/trainings.service';
import Nav from 'src/common/nav/Nav';
import Copyright from 'src/common/components/Copyright';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const TrainingCardsV3 = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const navigate = useNavigate()

  const [step, setStep] = useState(1)

  const [modulePreview, setModulePreview] = useState(null)
  const [training, setTraining] = useState(null)
  const [modules, setModules] = useState([])
  const [loadingModules, setLoadingModules] = useState(true)
  const [activeCard, setActiveCard] = useState(1)
  const [activeModule, setActiveModule] = useState(0)
  const [answerSelected, setAnswerSelected] = useState(null)
  const [displayProps, setDisplayProps] = useState(['block', 'block', 'block', 'block'])

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const currentLang = localStorage.getItem('lang')
    ? localStorage.getItem('lang') == 'pl'
      ? 'Polish'
      : 'English'
    : 'English'

  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 1024)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)

    return () => {
      window.removeEventListener('resize', handleWindowResizeEvent)
    }
  }, [])

  useEffect(() => {
    if (!query.get('id')) {
      navigate('/not-fonund')
    } else {
      if (query.get('skipIntro') == '1') {
        const now = dayjs().unix()  //TODO: refactor repeating requests

        if (localStorage.getItem('start-trainig-time')) {
          const localStartTime = parseInt(localStorage.getItem('start-trainig-time'))
          if (now > localStartTime + 1) {
            handleStart()
            localStorage.setItem('start-trainig-time', now.toString())
          }
        } else {
          localStorage.setItem('start-trainig-time', now.toString())
          handleStart()
        }
      } else {
        setLoadingModules(true)
        trainingsService.getTrainingModules(query.get('id'), currentLang).then(resp => {
          setModules(resp.data)
          setLoadingModules(false)
        }, (err) => {
          if (err.response.status == 401) {
            navigate('/login')
          }
        }).catch(() => {
          setLoadingModules(false)
        })
      }

    }
  }, [t])

  const handleStart = () => {
    if (query.get('id')) {

      setStep(2)
      trainingsService.startTraining(query.get('id'), currentLang).then(resp => {
        setTraining(resp.data)
        const localActiveModules = localStorage.getItem('activeModules')
        if (localActiveModules) {
          if (JSON.parse(localActiveModules).find(el => el.id == query.get('id'))) {
            setActiveModule(JSON.parse(localActiveModules).find(el => el.id == query.get('id')).module || 0)
            setModulePreview(resp.data.modules[JSON.parse(localActiveModules).find(el => el.id == query.get('id')).module])
          } else {
            setModulePreview(resp.data.modules[0])
          }
        } else {
          setModulePreview(resp.data.modules[0])
        }
      }, (err) => {
        if (err.response.status == 401) {
          navigate('/login')
        }
        if (err.response.status == 400) {
          if (err.response.data.code == 'TRAINING_PACKAGE_ERROR_006') {
            navigate('/trainings')
          } else {
            navigate('/not-fonund')
          }
        }
        if (err.response.status == 500) {
          navigate('/not-fonund')
        }
        if (err.response.status == 404) {
          navigate('/trainings')
        }
      })
    } else {
      navigate('/not-fonund')
    }
  }

  const onFinishTraining = () => {
    const currentLang = localStorage.getItem('lang')
      ? localStorage.getItem('lang') == 'pl'
        ? 'Polish'
        : 'English'
      : 'English'

    const updatedActiveModules = JSON.parse(localStorage.getItem('activeModules') || '[]').filter(el => el.id == query.get('id'))
    localStorage.setItem('activeModules', JSON.stringify(updatedActiveModules))

    trainingsService.finishTraining(
      training.trainingPackageId,
      currentLang,
      training.modules.map(el => (
        {
          trainingModuleId: el.trainingModuleId,
          answer: el.quizAnswers.find(el => el.isCorrect).answer
        })))
      .then((resp) => {
        navigate('/?earnedStars=' + resp.data.earnedStars)
      })
  }

  const handleNext = () => {
    if (activeCard == 4) {
      onFinishTraining()
    }
    if (activeCard == 3 && activeModule + 1 == training.modules.length) {
      setActiveCard(activeCard + 1)
      return
    }
    if (activeCard == 3) {
      setAnswerSelected(null)
      setActiveModule(activeModule + 1)
      setModulePreview(training.modules[activeModule + 1])
      setActiveCard(1)
      if (localStorage.getItem('activeModules')) {
        const updatedActiveModules = JSON.parse(localStorage.getItem('activeModules')).map(el => (el.id == query.get('id') ? { ...el, module: activeModule + 1 } : el))
        localStorage.setItem('activeModules', JSON.stringify(updatedActiveModules))
      } else {
        localStorage.setItem('activeModules', JSON.stringify([{ id: query.get('id'), module: activeModule + 1 }]))
      }
    }
    if (activeCard < 3) {
      setActiveCard(activeCard + 1)
    }
    setDisplayProps(displayProps.map((el, i) => (i == (activeCard - 1) ? 'none' : el)))
  }
  const handlePerv = () => {
    if (activeCard > 1) {
      setActiveCard(activeCard - 1)
    }
    setDisplayProps(displayProps.map((el, i) => (i == (activeCard - 1) ? 'block' : el)))
  }

  return (
    step == 1
      ?
      <div className='column' style={{ height: '100vh' }}>
        <Nav />
        <Spacer></Spacer>
        <div className='column column-center'>
          <form onSubmit={() => { }} className='login-form-container mob-login-form-container column'>
            <>

              <p className='login-title'>{t('You are assigned to new training package')}</p>

              <p style={{ opacity: 0.6, margin: '16px 0px 12px 0px' }}>{t('Training package includes')}</p>
              <div style={{ position: 'relative' }}>

                <div className='column' style={{ maxHeight: 150, overflow: 'auto', position: 'relative' }}>
                  {loadingModules ? <div className='column'>
                    <Skeleton sx={{ mb: '8px' }} width={264} height={18} />
                    <Skeleton sx={{ mb: '8px' }} width={270} height={18} />
                    <Skeleton sx={{ mb: '8px' }} width={214} height={18} />
                  </div> : modules.map((el, index) => <p style={{ fontWeight: 600, marginBottom: 8, }} key={index + 1}><span style={{ opacity: 0.7, minWidth: 24, display: 'inline-block' }}>{index < 9 ? '0' : ''}{index + 1}</span> {el.module}</p>)
                  }
                </div>
                {modules.length > 6 && <div style={{ position: 'absolute', height: '40px', bottom: 0, left: 0, width: '100%', background: 'linear-gradient(0deg, white, transparent)' }}></div>}
              </div>




              <LoadingButton
                type="submit"
                loading={loadingModules}
                onClick={handleStart}
                sx={{ mt: '30px', borderRadius: '14px', textTransform: 'none', height: isMobile ? '50px' : '60px', backgroundColor: '#6123FF', fontFamily: 'Satoshi', fontWeight: 900 }}
                variant={'contained'}
              >{t('Start training')} </LoadingButton >
            </>


          </form>

        </div>
        <Spacer></Spacer>
        <div className='column column-center' >
          <Copyright />

        </div>
      </div>
      :
      <div className='cards-v3'>
        <div style={{ width: '100%', height: 3, position: 'absolute', top: 0, left: 0, overflow: 'hidden' }}>
          <div style={{ height: 3, background: '#ffffff', opacity: 0.7, transition: '300ms', width: (training && Math.round((100 / training.modules.length) * (activeModule + 1) + ((100 / training.modules.length) / 3) * activeCard) - (100 / training.modules.length) + '%') || '0%' }}></div>
        </div>
        {/* <div onClick={() => { }} style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}></div> */}
        <IconButton disabled={activeCard == 1} style={{ opacity: activeCard == 1 && '0', ...(isMobile && { position: 'absolute', bottom: 10, zIndex: '10', left: 'calc(50% - 100px)' }) }} onClick={handlePerv}>
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="32.4875" cy="32.4875" rx="32.4875" ry="32.4875" transform="matrix(-1 0 0 1 65 0)" fill="#271C60" fillOpacity="0.93" />
            <path d="M36.0543 25L28.0059 33L36.0543 41" stroke="#8883A1" strokeWidth="2.14039" strokeLinecap="round" />
          </svg>

        </IconButton>
        <div className='cards-carousel'>
          {modulePreview && (
            <>
              {activeModule + 1 == training.modules.length && (
                <div style={{ transform: `rotate(${-16 + (activeCard * 4)}deg)`, ...(((activeModule + 1 == training?.modules.length) && activeCard == 4) && { zIndex: '10' }) }} className='cards-v3-item column column-center'>
                  <h2>{t('Training successfully completed')}</h2>
                  <div style={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: '300px' }} src="images/training-success-complete.png" alt="" />
                  </div>
                  <Button sx={{ textTransform: 'none', fontSize: '20px', backgroundColor: '#322171', borderRadius: '8px', cursor: 'pointer', '&.MuiButton-root': { backgroundColor: '#402b8b' } }} variant='contained' onClick={() => { onFinishTraining() }}>{t('End training')}</Button>
                </div>
              )}
              <div style={{ transform: `rotate(${-12 + (activeCard * 4)}deg)`, display: activeCard < 4 ? 'block' : displayProps[2], opacity: activeCard < 4 ? '1' : '0' }} className='cards-v3-item'>
                <h2>{t('Quiz')}</h2>
                <p>{modulePreview?.quizQuestion}</p>
                {modulePreview?.quizAnswers.map(el =>
                  <div
                    className={`answer-option row row-center ${answerSelected?.answer == el.answer && el.isCorrect && 'answer-option-correct'} ${answerSelected?.answer == el.answer && !el.isCorrect && 'answer-option-wrong'}`}
                    key={el.answer.toLowerCase().replaceAll(' ', '')}
                    onClick={() => !answerSelected && setAnswerSelected(el)}
                  >
                    <div className='answer-option-marker'></div>
                    {el.answer}
                    <Spacer />
                    {answerSelected?.answer == el.answer && el.isCorrect && <>
                      <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.975586 6.73649L8.0191 13.3171L18.9757 1.31714" stroke="#1ED800" strokeWidth="1.5" strokeLinecap="round" />
                      </svg>

                    </>}
                    {answerSelected?.answer == el.answer && !el.isCorrect && <>
                      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.96973 7.97046L21.9697 21.9705" stroke="#FF0000" strokeWidth="1.23744" strokeLinecap="round" />
                        <path d="M7.96973 21.9705L21.9697 7.97046" stroke="#FF0000" strokeWidth="1.23744" strokeLinecap="round" />
                      </svg>


                    </>}
                    {answerSelected?.answer == el.answer && !el.isCorrect &&
                      <div className='explanation-wrong'><span>{el.explanation}</span></div>
                    }
                  </div>
                )}
                {answerSelected && answerSelected?.isCorrect && <p>{t('That’s right!')}</p>}
                {answerSelected && !answerSelected?.isCorrect && <Button
                  style={{ background: '#3D306D', color: '#fff', textTransform: 'none' }}
                  onClick={() => setAnswerSelected(null)}>{t('Reset quiz')}</Button>}
              </div>
              <div style={{ transform: `rotate(${-8 + (activeCard * 4)}deg)`, display: activeCard < 3 ? 'block' : displayProps[1], opacity: activeCard < 3 ? '1' : '0' }} className='cards-v3-item'>
                <h2>{t('What to do?')}</h2>
                <ul style={{ marginBottom: '10px' }}>
                  {modulePreview.whatToDo.map(el =>
                    <div className="row" key={el.replaceAll(' ', '').toLowerCase()}>
                      <svg style={{ marginTop: '8px', marginRight: '12px', flexShrink: '0' }} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="4.97309" cy="5.16731" rx="4.25531" ry="4.25532" fill="#1ED800" />
                      </svg>
                      <p style={{ marginBottom: '6px' }}>{el}</p></div>)}
                </ul>
                <h3 style={{ marginBottom: 10 }}>{t('Importance of Topic')}</h3>
                <p>{modulePreview.importance}</p>
                <h3 style={{ marginBottom: 10 }}>{t('Real Life Example')}</h3>
                <p>{modulePreview.realLifeExample}</p>
              </div>
              <div style={{ transform: `rotate(${-4 + (activeCard * 4)}deg)`, display: activeCard < 2 ? 'block' : displayProps[0], opacity: activeCard < 2 ? '1' : '0' }} className='cards-v3-item column'>
                <h2>{modulePreview.module}</h2>
                <p>{modulePreview.description}</p>
                <div style={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center' }}>
                  <img style={{ height: '300px' }} src={modulePreview.imageUrl} alt="" />
                </div>
              </div>
            </>
          )}
        </div>
        <IconButton disabled={activeCard == 3 ? !(answerSelected && answerSelected?.isCorrect) : false} style={{ opacity: ((activeModule + 1 == training?.modules.length) && activeCard == 4) ? '0' : activeCard == 3 ? (answerSelected && answerSelected?.isCorrect ? '1' : '0') : '1', ...(isMobile && { position: 'absolute', bottom: 10, zIndex: '10', right: 'calc(50% - 100px)' }) }} onClick={handleNext}>
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="32.4875" cy="32.4875" rx="32.4875" ry="32.4875" transform="matrix(-1 0 0 1 65 0)" fill="#271C60" />
            <path d="M29.9457 25L37.9941 33L29.9457 41" stroke="#8883A1" strokeWidth="1.60003" strokeLinecap="round" />
          </svg>

        </IconButton>
      </div >

  )
}

export default TrainingCardsV3
