import axios from 'axios';
import authHeader from './auth-header';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = window._env_.REACT_APP_API_HOST;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/v1/',
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem('app_user'))

      headers.set('Authorization', `Bearer ${user.accessToken}`)
    }
  }),
  endpoints: (builder) => ({
    getAccount: builder.query({
      query: () => `account`,
    }),
    getCountries: builder.query({
      query: () => `countries`,
    }),
    getLanguages: builder.query({
      query: () => `languages`,
    }),
    getDepartments: builder.query({
      query: () => `departments`,
    }),
    getCurrentActivity: builder.query({
      query: () => `activity/users/current`,
    }),
    getSimulationStats: builder.query({
      query: () => `users/current/simulation-stats`,
    }),
  }),
})

export const {
  useGetAccountQuery,
  useGetCountriesQuery,
  useGetLanguagesQuery,
  useGetDepartmentsQuery,
  useGetCurrentActivityQuery,
  useGetSimulationStatsQuery
} = userApi

//
class UserService {
  getUser(id) {
    return axios.get(API_URL + '/v1/users/' + id, { headers: authHeader() });
  }
  getUsers(filters) {
    const params = new URLSearchParams(filters)
    return axios.get(API_URL + '/v1/users', {
      headers: authHeader(), params
    });
  }
  delete(id) {
    return axios.delete(API_URL + '/v1/users/' + id, { headers: authHeader() });
  }
  deleteMany(ids) {
    return axios.delete(API_URL + '/v1/users/', { data: ids }, { headers: authHeader() });
  }
  update(id, payload) {
    return axios.put(API_URL + '/v1/users/' + id, payload, { headers: authHeader() });
  }
  getAccountData() {
    return axios.get(API_URL + '/v1/account', { headers: authHeader() });
  }
  updateAccountData(accountData) {
    return axios.put(API_URL + '/v1/account', accountData, { headers: authHeader() });
  }
  onboardUser(accountData) {
    return axios.put(API_URL + '/v1/account/onboarding', accountData, { headers: authHeader() });
  }
  updatePassword(newPassword) {
    return axios.post(API_URL + '/v1/account/change-password', { newPassword }, { headers: authHeader() });
  }
  resetPasswordFinish(token, password) {
    return axios.post(API_URL + '/v1/account/reset-password/finish', { token, password }, { headers: authHeader() });
  }
  getCurrentActivity() {
    return axios.get(API_URL + '/v1/activity/users/current', { headers: authHeader() });
  }
  getSimulationStats() {
    return axios.get(API_URL + '/v1/users/current/simulation-stats', { headers: authHeader() });
  }
}

export default new UserService();
