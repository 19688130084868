import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window._env_.REACT_APP_API_HOST;

class CardsService {
  getCards(id) {
    return axios.get(API_URL + '/v1/simulations/' + id + '/micro-trainings');
  }
  getCardsTemplate(id) {
    return axios.get(API_URL + '/v1/simulations/' + id + '/email');
  }
  followLink(id) {
    return axios.post(API_URL + '/v1/simulations/' + id + '/follow-link');
  }
  followQrLink(id) {
    return axios.post(API_URL + '/v1/simulations/' + id + '/follow-qr-code-link');
  }
  finishTraining(id) {
    return axios.post(API_URL + '/v1/simulations/' + id + '/micro-trainings/finish');
  }
}

export default new CardsService();
