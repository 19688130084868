import React from 'react'

const LogoutIcon = ({ color = 'white' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.3125 8.0625L20.249 12L16.3125 15.9375' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.75 12H20.2472' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12.75 20.25H4.875C4.57663 20.25 4.29048 20.171 4.07951 20.0303C3.86853 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.86853 4.11032 4.07951 3.96967C4.29048 3.82902 4.57663 3.75 4.875 3.75H12.75'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      opacity='0.2'
      d='M12.75 20.25H4.875C4.57663 20.25 4.29048 20.171 4.07951 20.0303C3.86853 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.86853 4.11032 4.07951 3.96967C4.29048 3.82902 4.57663 3.75 4.875 3.75H12.75'
      fill={color}
    />
  </svg>
)

export default LogoutIcon
