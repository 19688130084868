import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { jiraPage } from './jira';
import cardsService from "src/services/cards.service";
import { useQuery } from "src/hooks";

var template = { __html: jiraPage };

const JiraLogin = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const [templateCode, id, mail] = query.get('redirect-link').split(',');

  const lang = query.get('language') ? query.get('language') : ''
  const hasQr = query.get('qrCode') == 'true'

  useEffect(() => {
    if (hasQr) {
      cardsService.followQrLink(id).then(() => {
        sessionStorage.setItem('followLink', id)
      })
    } else {
      cardsService.followLink(id).then(() => {
        sessionStorage.setItem('followLink', id)
      })
    }
  }, [])

  return (
    <>
      <div id="__nuxt">
        <style dangerouslySetInnerHTML={template} ></style>

        <div className="relative h-screen pt-10 bg-white">
          <div className="mx-auto max-w-400">
            <svg viewBox="0 0 190 32" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"
              fill="#0052CC" className="h-10 mx-auto md:mb-10">
              <defs>
                <lineargradient id="uid65" x1="99.684716%" y1="15.8138128%" x2="39.8444399%" y2="97.4388388%">
                  <stop stopColor="#0052CC" offset="0%"></stop>
                  <stop stopColor="#2684FF" offset="100%"></stop>
                </lineargradient>
              </defs>
              <g stroke="none" strokeWidth="1" fillRule="nonzero">
                <path d="M6.90502605,15.6123193 C6.76436383,15.4302139 6.53773035,15.3340846 6.30742588,15.35884 C6.0771214,15.3835955 5.876643,15.525635 5.7787929,15.7333781 L0.0719979599,27.0218487 C-0.0337056449,27.2310259 -0.0224063827,27.4794358 0.101860917,27.6783741 C0.226128216,27.8773125 0.445645594,27.9984148 0.68202605,27.9984369 L8.62844459,27.9984369 C8.88847261,28.0044096 9.12761649,27.8581627 9.23847268,27.6253781 C10.9526159,24.1210252 9.91378448,18.7926722 6.90502605,15.6123193 Z"
                  fill="url(#uid65)"></path>
                <path d="M11.0859556,5.33713587 C8.19309829,9.74089822 7.85921851,15.3267488 10.2073011,20.0371359 L14.0383488,27.6176065 C14.1538739,27.8462194 14.3900332,27.9906411 14.6483769,27.9906653 L22.5933685,27.9906653 C22.829749,27.9906431 23.0492663,27.8695408 23.1735336,27.6706025 C23.2978009,27.4716641 23.3091002,27.2232543 23.2033966,27.014077 C23.2033966,27.014077 12.5147056,5.8619594 12.2460792,5.33290058 C12.1377032,5.11315026 11.9118188,4.97410225 11.6646746,4.97500451 C11.4175304,4.97590676 11.1926893,5.11660025 11.0859556,5.33713587 L11.0859556,5.33713587 Z"
                  fill="#2684FF"></path>
                <path d="M104.2774,14.3919316 C104.2774,17.1872257 105.588069,19.4065198 110.714802,20.3862846 C113.773504,21.0215787 114.414212,21.5100493 114.414212,22.5187551 C114.414212,23.4985198 113.772077,24.1327551 111.617715,24.1327551 C109.013896,24.0864379 106.462135,23.403307 104.189999,22.1442846 L104.189999,26.6972257 C105.733976,27.4465198 107.772754,28.2822846 111.559566,28.2822846 C116.919251,28.2822846 119.045788,25.9175787 119.045788,22.4033434 M119.045788,22.4033434 C119.045788,19.0892257 117.268858,17.5327551 112.25878,16.4668728 C109.491535,15.8615787 108.821574,15.2566375 108.821574,14.3919316 C108.821574,13.297814 109.811889,12.835814 111.646968,12.835814 C113.860906,12.835814 116.045591,13.4986375 118.113622,14.4208728 L118.113622,10.0691081 C116.130615,9.17615406 113.970906,8.73311319 111.792518,8.7724022 C106.840589,8.7724022 104.2774,10.9048728 104.2774,14.3919316"
                  fill="inherit"></path>
                <polygon fill="inherit"
                  points="173.129997 9.07000017 173.129997 28.0038825 177.20791 28.0038825 177.20791 13.5657649 178.926691 17.3983531 184.694132 28.0038825 189.820865 28.0038825 189.820865 9.07000017 185.742952 9.07000017 185.742952 21.2891766 184.198975 17.7442355 179.567399 9.07000017"></polygon>
                <rect fill="inherit" x="142.740005" y="9.07000017" width="4.45677247" height="18.9338824"></rect>
                <path d="M137.600792,22.4033434 C137.600792,19.0892257 135.823862,17.5327551 130.813784,16.4668728 C128.046539,15.8615787 127.376579,15.2566375 127.376579,14.3919316 C127.376579,13.297814 128.366893,12.835814 130.201972,12.835814 C132.41591,12.835814 134.600595,13.4986375 136.668626,14.4208728 L136.668626,10.0691081 C134.685619,9.17615406 132.52591,8.73311319 130.347522,8.7724022 C125.395593,8.7724022 122.832404,10.9048728 122.832404,14.3919316 C122.832404,17.1872257 124.143073,19.4065198 129.269806,20.3862846 C132.328508,21.0215787 132.969216,21.5100493 132.969216,22.5187551 C132.969216,23.4985198 132.327081,24.1327551 130.172719,24.1327551 C127.568901,24.0864379 125.017139,23.403307 122.745003,22.1442846 L122.745003,26.6972257 C124.28898,27.4465198 126.327758,28.2822846 130.11457,28.2822846 C135.474256,28.2822846 137.600792,25.9175787 137.600792,22.4033434"
                  fill="inherit"></path>
                <polygon fill="inherit"
                  points="69.6599979 9.07000017 69.6599979 28.0038825 78.8204081 28.0038825 80.2627142 23.9115296 74.1456665 23.9115296 74.1456665 9.07000017"></polygon>
                <polygon fill="inherit"
                  points="51.5549984 9.07000017 51.5549984 13.1620002 56.5069282 13.1620002 56.5069282 28.0038825 60.9925967 28.0038825 60.9925967 13.1620002 66.2941332 13.1620002 66.2941332 9.07000017"></polygon>
                <path d="M45.0573091,9.07000017 L39.1785647,9.07000017 L32.5050001,28.0038825 L37.6014102,28.0038825 L38.5474889,24.815059 C40.877531,25.4919503 43.3551322,25.4919503 45.6851743,24.815059 L46.6312529,28.0038825 L51.7287333,28.0038825 L45.0573091,9.07000017 Z M42.1177585,21.4007061 C41.287584,21.4006584 40.4616854,21.2831148 39.6651602,21.0516472 L42.1177585,12.7889413 L44.5703569,21.0544708 C43.7736914,21.2849831 42.9477956,21.4015755 42.1177585,21.4007061 L42.1177585,21.4007061 Z"
                  fill="inherit"></path>
                <path d="M94.6019534,9.07000017 L88.7235658,9.07000017 L82.0500011,28.0038825 L87.1474815,28.0038825 L88.0935601,24.815059 C90.4236023,25.4919503 92.9012034,25.4919503 95.2312455,24.815059 L96.1773242,28.0038825 L101.274805,28.0038825 L94.6019534,9.07000017 Z M91.6627596,21.4007061 C90.8325851,21.4006584 90.0066865,21.2831148 89.2101613,21.0516472 L91.6627596,12.7889413 L94.1153579,21.0544708 C93.3186924,21.2849831 92.4927966,21.4015755 91.6627596,21.4007061 L91.6627596,21.4007061 Z"
                  fill="inherit"></path>
                <path d="M163.256954,9.07000017 L157.378566,9.07000017 L150.705002,28.0038825 L155.802482,28.0038825 L156.748561,24.815059 C159.078603,25.4919503 161.556204,25.4919503 163.886246,24.815059 L164.832325,28.0038825 L169.930162,28.0038825 L163.256954,9.07000017 Z M160.315977,21.4007061 C159.485802,21.4006584 158.659903,21.2831148 157.863378,21.0516472 L160.315977,12.7889413 L162.768575,21.0544708 C161.971909,21.2849831 161.146014,21.4015755 160.315977,21.4007061 L160.315977,21.4007061 Z"
                  fill="inherit"></path>
              </g>
            </svg>
            <form className="flex flex-col items-center px-10 py-8 text-sm bg-white rounded md:shadow-atlassian">
              <div className="mb-6 text-base text-blueGray-500">Log in to continue</div>
              <input value={mail}
                className="w-full h-10 px-2 mb-3 border-2 border-gray-200 rounded text-blueGray-800 bg-gray-50"
                placeholder="Enter email" type="email" required="" />
              <input
                className="w-full h-10 px-2 mb-8 border-2 border-gray-200 rounded text-blueGray-800 bg-gray-50"
                placeholder="Enter password"
                type="password" required=""
                autoFocus="" />
              <button onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)}
                className="w-full h-10 mb-4 font-medium text-white bg-blue-700 rounded hover:bg-blue-600"
                type="button">Log in
              </button>
              <div className="mb-4 text-xs text-blueGray-400">OR</div>
              <a onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)} type="button" aria-label="Google"
                className="flex items-center justify-center w-full h-10 mb-4 font-bold border border-gray-200 rounded shadow-md hover:bg-gray-50 text-blueGray-600">
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                  <g fill="none" fillRule="evenodd">
                    <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                      fill="#EA4335"></path>
                    <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                      fill="#4285F4"></path>
                    <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                      fill="#FBBC05"></path>
                    <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                      fill="#34A853"></path>
                    <path d="M0 0h18v18H0z"></path>
                  </g>
                </svg>
                Continue with Google</a><a
                  onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)} type="button"
                  className="flex items-center justify-center w-full h-10 mb-4 font-bold border border-gray-200 rounded shadow-md hover:bg-gray-50 text-blueGray-600">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" className="mr-2">
                  <rect x="1" y="1" width="9" height="9" fill="#f25022"></rect>
                  <rect x="1" y="11" width="9" height="9" fill="#00a4ef"></rect>
                  <rect x="11" y="1" width="9" height="9" fill="#7fba00"></rect>
                  <rect x="11" y="11" width="9" height="9" fill="#ffb900"></rect>
                </svg>
                Continue with Microsoft
              </a><a onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)} type="button"
                className="flex items-center justify-center w-full h-10 mb-8 font-bold border border-gray-200 rounded shadow-md hover:bg-gray-50 text-blueGray-600">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-6 h-6 mr-1 mb-1">
                  <path fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                </svg>
                Continue with Apple</a>
              <hr className="w-full mb-4" />
              <div className="flex text-center text-blue-700"><a
                onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)}
                className="hover:underline">Can't log in?</a><span className="mx-3">•</span><a
                  onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)}
                  className="hover:underline">Sign up for an account</a></div>
            </form>
            <div className="flex justify-center w-full mt-6 text-xs text-center text-blue-700"><a
              onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)}
              className="hover:underline">Privacy policy</a><span className="mx-3">•</span><a
                onClick={() => navigate(`/cards-v2?id=${templateCode},${id}${lang ? '&language=' + lang : ''}`)}
                className="hover:underline">User notice</a></div>
          </div>
        </div>
      </div>
    </>


  )
}

export default JiraLogin