import React, { useEffect, useState } from 'react'

import { NavLink, useNavigate } from 'react-router-dom'
import './styles.css'

import Logo from '../logo'
import DashBoardIcon from '../icons/DashBoardIcon'
import TrainingIcon from '../icons/training'
import AuthService from 'src/services/auth.service'
import userService, { useGetAccountQuery, useGetCountriesQuery, useGetDepartmentsQuery, useGetLanguagesQuery } from 'src/services/user.service'
import Spacer from '../components/Spacer'
import { Button, Drawer, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { styled } from '@mui/material/styles'
import LogoutIcon from '../icons/logout'
import { LoadingButton } from '@mui/lab'
// import NewPasswordDialog from './components/newPasswordDialog'
import { useTranslation } from 'react-i18next'
import i18next from '../../i18n'
import { useContext } from 'react'
import { SettingsContext } from 'src/App'
import trainingsService from 'src/services/trainings.service'

const InputSelect = styled(Select)(() => ({
  // border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E7E7EE'
  },

  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

  '& .MuiInputLabel-root': {
    top: '12px'
  }

}))

function Nav({ light, handleShowTips }) {
  const { t } = useTranslation()

  const [mobileMenuShown, setMobileMenuShown] = useState(false)
  const [error, setError] = useState(null)
  const [accountData, setAccountData] = useState(null)
  // const { data: accountData, error } = useGetAccountQuery()
  const { data: countriesData } = useGetCountriesQuery()
  const { data: languagesData } = useGetLanguagesQuery()
  const { data: departmentsData } = useGetDepartmentsQuery()

  const collapsed = false
  const [form, setForm] = useState({})
  const [fromChanged, setFormChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  const [totalActive, setTotalActive] = useState(0)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const { setLang } = useContext(SettingsContext)

  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 1024)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)

    trainingsService.getActiveTrainings().then((resp) => {
      setTotalActive(resp.data.length)
    })

    return () => {
      window.removeEventListener('resize', handleWindowResizeEvent)
    }
  }, [])

  const handleOpenMenu = () => {
    document.body.classList.toggle('lock-scroll')
    setMobileMenuShown(!mobileMenuShown)
  }
  const handleCloseMenu = () => {
    document.body.classList.remove('lock-scroll')
    setMobileMenuShown(false)
  }

  const getAccountLangCode = (acc) => {
    if (acc.language) {
      return acc.language == 'Polish' ? 'pl' : 'en'
    } else if (acc.companyLanguage) {
      return acc.companyLanguage == 'Polish' ? 'pl' : 'en'
    } else {
      return 'en'
    }
  }

  useEffect(() => {
    userService.getAccountData().then(resp => {
      setAccountData(resp.data)

      if (resp.data) {
        setLang(resp.data?.language ? resp.data?.language : resp.data?.companyLanguage ? resp.data?.companyLanguage : "English")
        localStorage.setItem('lang', getAccountLangCode(resp.data))
        i18next.changeLanguage(getAccountLangCode(resp.data))
        setForm({
          department: resp.data?.department,
          country: resp.data?.country,
          language: resp.data?.language,
        })
      }
    }).catch(err => {
      console.error(err);
      setError(err)
    })

  }, [])

  const handleSave = () => {
    setLoading(true)
    userService.updateAccountData({
      ...accountData,
      department: form.department,
      country: form.country,
      language: form.language,
    }).then(() => {
      setLoading(false)
      setFormChanged(false)
      localStorage.setItem('lang', form.language == 'Polish' ? 'pl' : 'en')
      i18next.changeLanguage(form.language == 'Polish' ? 'pl' : 'en')
      setLang(form.language)
    })
  }

  const navigate = useNavigate()

  const handleLogout = () => {
    AuthService.logout().then(() => {
      navigate('/login')
      localStorage.removeItem('app_user')
    }).catch(() => {
      localStorage.removeItem('app_user')
      navigate('/login')
    })
  }

  useEffect(() => {
    if (error && error.status == 401) {
      handleLogout()
    }
  }, [error])

  const [viewUsersData, setViewUsersData] = useState(false)

  return (
    <div className={`navigation row row-center ${light && 'navigation-light'}`}>
      <NavLink className={'logo-link'} to={'/'}>
        <Logo collapsed={collapsed} light={light} />
      </NavLink>
      <Spacer />
      {isMobile
        ?
        <div onClick={handleOpenMenu} className="burger-menu-button">
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H17.9987" stroke={light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
            <path d="M1 8.00049H17.9987" stroke={light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
            <path d="M1 15H17.9987" stroke={light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
        :
        <>
          <NavLink className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/'}>
            <DashBoardIcon light={light} /> {collapsed ? '' : t('Dashboard')}
          </NavLink>
          <NavLink className={`nav-item row row-center ${collapsed && 'nav-item-collapsed'}`} to={'/trainings'} style={{ marginRight: 64, }}>
            <TrainingIcon light={light} /> {collapsed ? '' : t('Trainings')}
            {totalActive > 0 && <p className='badge'>{totalActive}</p>}
          </NavLink>

          {!light && <button className='gradient1 row row-center bell-button' onClick={handleShowTips} style={{}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.2" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#fff" />
              <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z" fill="#fff" />
              <path d="M12 13.5V12.75C12.5192 12.75 13.0267 12.596 13.4584 12.3076C13.8901 12.0192 14.2265 11.6092 14.4252 11.1295C14.6239 10.6499 14.6758 10.1221 14.5746 9.61289C14.4733 9.10369 14.2233 8.63596 13.8562 8.26885C13.489 7.90173 13.0213 7.65173 12.5121 7.55044C12.0029 7.44915 11.4751 7.50114 10.9955 7.69982C10.5158 7.8985 10.1058 8.23495 9.81739 8.66663C9.52895 9.09831 9.375 9.60583 9.375 10.125" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
            </svg>


          </button>}

          {accountData && <button onClick={() => setViewUsersData(true)} className='gradient1 row row-center accout-button' style={{}}>
            <div className='accout-button-avatar'>
              {(accountData?.firstName ? accountData?.firstName[0] : '') + '' + (accountData?.lastName ? accountData?.lastName[0] : '')}
            </div>
            <p className='accout-button-name'>{(accountData?.firstName || '') + ' ' + (accountData?.lastName || '')}</p>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.5">
                <path d="M3.75 6.66797L10 12.918L16.25 6.66797" stroke={light ? '#1B1B26' : 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>


          </button>}
        </>
      }

      {mobileMenuShown &&
        <div className="mobile-menu">
          <div className="row row-center">
            <Logo theme={'dark'} />
            <Spacer />
            <div onClick={handleCloseMenu} className="burger-menu-button">
              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.24219 6.75391L18.7422 19.2539" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                <path d="M6.24219 19.2461L18.7422 6.7461" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
              </svg>

            </div>
          </div>
          <div style={{ paddingTop: 20 }} className='column'>
            <NavLink className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/'}>
              <DashBoardIcon light={false} /> {collapsed ? '' : 'Dashboard'}
            </NavLink>
            <NavLink className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/trainings'}>
              <TrainingIcon light={false} /> {collapsed ? '' : 'Trainings'}
            </NavLink>
          </div>
          <Spacer />
          <div className='row row-center'>
            {!light && <button className='gradient1 row row-center bell-button' onClick={() => {
              handleShowTips()
              handleCloseMenu()
            }} style={{ marginTop: 20, marginRight: 20 }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#fff" />
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z" fill="#fff" />
                <path d="M12 13.5V12.75C12.5192 12.75 13.0267 12.596 13.4584 12.3076C13.8901 12.0192 14.2265 11.6092 14.4252 11.1295C14.6239 10.6499 14.6758 10.1221 14.5746 9.61289C14.4733 9.10369 14.2233 8.63596 13.8562 8.26885C13.489 7.90173 13.0213 7.65173 12.5121 7.55044C12.0029 7.44915 11.4751 7.50114 10.9955 7.69982C10.5158 7.8985 10.1058 8.23495 9.81739 8.66663C9.52895 9.09831 9.375 9.60583 9.375 10.125" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
              </svg>


            </button>}

            {accountData && <button onClick={() => setViewUsersData(true)} className='gradient1 row row-center accout-button' style={{}}>
              <div className='accout-button-avatar'>
                {(accountData?.firstName ? accountData?.firstName[0] : '') + '' + (accountData?.lastName ? accountData?.lastName[0] : '')}
              </div>
              <p className='accout-button-name'>{(accountData?.firstName || '') + ' ' + (accountData?.lastName || '')}</p>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path d="M3.75 6.66797L10 12.918L16.25 6.66797" stroke={light ? '#1B1B26' : 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </button>}
          </div>


        </div>
      }

      <Drawer anchor='right' onClose={() => setViewUsersData(false)} open={viewUsersData}>
        <div
          style={{ position: 'fixed', top: '24px', right: isMobile ? '368px' : '572px', cursor: 'pointer' }}
          onClick={() => setViewUsersData(false)}
        >
          <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.24219 6.75391L18.7422 19.2539" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
            <path d="M6.24219 19.2461L18.7422 6.7461" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
          </svg>
        </div>
        <div style={{ width: isMobile ? '350px' : '560px', padding: '32px' }} className='row row-center'>
          <h2 className='drawer-title'>{t('Profile')}</h2>
          <Spacer />
        </div>
        <div style={{ padding: '32px', flex: '1' }} className='column'>
          <div className='row'>
            <p style={{ width: '100px', marginBottom: '20px', opacity: '0.7' }}>{t('Name')}</p>
            <p style={{ fontWeight: 600 }}>{(accountData?.firstName || '') + ' ' + (accountData?.lastName || '')}</p>
          </div>
          <div style={{ marginBottom: '40px' }} className='row'>
            <p style={{ width: '100px', marginBottom: '20px', opacity: '0.7' }}>{t('Email')}</p>
            <p>{accountData?.email}</p>
          </div>
          {/* to update when images will work */}
          {/* <div style={{ marginBottom: '24px' }} className='row row-center'>
            <div className='profile-picture'>
              <img src="/images/avatar.jpg" alt="" />
            </div>
            <p>Your profile picture</p>
            <Spacer />
            <Button sx={{ border: '1px solid #E7E7EE', borderRadius: '12px' }} variant='outlined'><EditIcon /></Button>
          </div> */}

          <FormControl fullWidth>
            <InputLabel id="Department">{t('Department')}</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}

              labelId="Department"
              value={form?.department}
              label="Department"
              onChange={(e) => {
                setForm({ ...form, department: e.target.value })
                setFormChanged(true)
              }}
            >
              {departmentsData?.map(el => (
                <MenuItem key={el} value={el}>{el}</MenuItem>
              ))}
            </InputSelect>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="Location">{t('Location')}</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}

              labelId="Location"
              value={form?.country}
              label="Location"
              onChange={(e) => {
                setForm({ ...form, country: e.target.value })
                setFormChanged(true)
              }}
            >
              {countriesData?.map(el => (
                <MenuItem key={el} value={el}>{el}</MenuItem>
              ))}
            </InputSelect>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="Language">{t('Language')}</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}

              labelId="Language"
              value={form?.language}
              label="Language"
              onChange={(e) => {
                setForm({ ...form, language: e.target.value })
                setFormChanged(true)
              }}
            >
              {languagesData?.map(el => (
                <MenuItem key={el} value={el}>{el}</MenuItem>
              ))}
            </InputSelect>
          </FormControl>
          <div className='row row-center'>

            <Button style={{
              color: '#B40020',
              textTransform: 'none'
            }}
              startIcon={<LogoutIcon color={'#B40020'} />}
              onClick={handleLogout}
            >{t('signOut')}</Button>
            <Spacer />
            {fromChanged &&
              <LoadingButton
                loading={loading}
                onClick={handleSave}
                sx={{ borderRadius: '14px', textTransform: 'none', backgroundColor: '#6123FF', fontFamily: 'Plus Jakarta Sans', fontSize: '16px' }}
                variant={'contained'}
              >{t('Save Changes')}</LoadingButton>
            }
          </div>
          <Spacer />
          <p>© 2023 Cyberlift.ai. {t('copy')}</p>
        </div>
      </Drawer>

      {/* <NewPasswordDialog open={changePasswordDialog} onClose={() => setChangePasswordDialog(false)} /> */}

    </div>
  )
}

export default Nav
